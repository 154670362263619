<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5713 1.8598C12.7281 -0.297018 16.225 -0.297018 18.382 1.8598C20.5386 4.01665 20.5386 7.51356 18.382 9.67038L14.4766 13.5757L6.66602 5.76508L10.5713 1.8598Z"
      fill="#CECECF"
      fill-opacity="0.57"
    />
    <path
      d="M9.42819 18.6299C7.27137 20.7868 3.77445 20.7868 1.61763 18.6299C-0.53921 16.4731 -0.53921 12.9762 1.61763 10.8194L5.52293 6.91412L13.3335 14.7246L9.42819 18.6299Z"
      fill="#4ADE80"
    />
  </svg></template>
