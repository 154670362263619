import { defineStore } from "pinia";

export const useMenuStore = defineStore("menu", {
  state: () => ({
    isCollapsed: true, // По умолчанию меню свернуто
    isHidden: false, // По умолчанию меню видимо
  }),

  actions: {
    /**
     * Инициализация стора с проверкой localStorage
     */
    initialize() {
      const savedMenuState = localStorage.getItem("menuState");
      if (savedMenuState) {
        try {
          const state = JSON.parse(savedMenuState);
          this.isCollapsed = state.isCollapsed;
          this.isHidden = state.isHidden;
        } catch (e) {
          console.error("Failed to parse stored menu state", e);
        }
      }
    },

    /**
     * Переключение между свернутым и развернутым состоянием
     */
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
      this.saveState();
    },

    /**
     * Переключение видимости меню
     */
    toggleHidden() {
      this.isHidden = !this.isHidden;

      // Если меню становится видимым (было скрыто, теперь показывается)
      if (!this.isHidden) {
        // Всегда показываем его в свернутом виде для удобства
        this.isCollapsed = true;
      }

      this.saveState();
    },

    /**
     * Циклическое переключение состояний меню
     * (компактный -> развернутый -> скрытый -> компактный)
     */
    cycleMenuState() {
      if (this.isHidden) {
        // Если меню скрыто, показываем его в свернутом виде
        this.isHidden = false;
        this.isCollapsed = true;
      } else if (this.isCollapsed) {
        // Если меню свернуто, разворачиваем его
        this.isCollapsed = false;
      } else {
        // Если меню развернуто, скрываем его
        this.isHidden = true;
      }

      this.saveState();
    },

    /**
     * Сохранение состояния в localStorage
     */
    saveState() {
      localStorage.setItem(
        "menuState",
        JSON.stringify({
          isCollapsed: this.isCollapsed,
          isHidden: this.isHidden,
        })
      );
    },

    /**
     * Принудительная установка состояния меню
     */
    setState(state) {
      if (state && typeof state === "object") {
        if ("isCollapsed" in state) this.isCollapsed = state.isCollapsed;
        if ("isHidden" in state) this.isHidden = state.isHidden;
        this.saveState();
      }
    },
  },

  getters: {
    /**
     * Текущее состояние меню в виде объекта
     */
    menuState: (state) => ({
      isCollapsed: state.isCollapsed,
      isHidden: state.isHidden,
    }),
  },
});
