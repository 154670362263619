<template>
  <button
    v-if="!hasActiveVip"
    @click="$emit('open-modal')"
    class="relative text-nowrap flex text-[11px] items-center justify-center px-[4px] max-[1439px]:px-[4px] py-[1px] rounded-[5px] w-[90px] font-bold group"
  >
    <svg
      width="107"
      height="24"
      viewBox="0 0 107 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="transition-all duration-300"
    >
      <g>
        <rect
          width="107"
          height="24"
          rx="5"
          fill="white"
          class="group-hover:fill-[#f1f1f1]"
        />
        <rect
          x="0.25"
          y="0.25"
          width="106.5"
          height="23.5"
          rx="4.75"
          stroke="url(#paint0_linear_1528_1291)"
          stroke-opacity="0.5"
          stroke-width="0.5"
        />
        <foreignObject x="10%" y="10%" width="80%" height="500%">
          <div class="flex items-center justify-center gap-1">
            <span
              xmlns="http://www.w3.org/1999/xhtml"
              class="bg-gradient-to-r font-[400] max-[1439px]:text-[8px] from-[#ffa563] to-[#ff3b3b] text-transparent bg-clip-text transition-all duration-300 group-hover:text-[#797979]/10"
            >
              {{ $t("articlebalance.vip") }}
            </span>
            <DiamondIcon />
          </div>
        </foreignObject>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1528_1291"
          x1="107"
          y1="12"
          x2="0"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00DEFF" />
          <stop offset="1" stop-color="#F92056" />
        </linearGradient>
      </defs>
    </svg>
  </button>
</template>

<script setup>
import DiamondIcon from "../components/icons/DiamondIcon.vue";
import { useUserStore } from "../stores/userStore";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const emit = defineEmits(["open-modal"]);
const { user } = storeToRefs(useUserStore());

const hasActiveVip = computed(() => {
  return user.value?.subscriptions?.some(
    (sub) => sub.plan === "vip" && sub.is_active
  );
});
</script>
