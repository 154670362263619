<template>
  <div class="relative">
    <div
      class="flex items-center gap-[5px] text-[#797979] cursor-not-allowed"
      :class="isCollapsed ? 'justify-center flex-col' : 'justify-start'"
    >
      <FujersIcon
        :class="isCollapsed ? 'w-[35px] h-[35px]' : 'w-[20px] h-[20px] mr-1.5'"
      />
      <span :class="isCollapsed ? 'text-[12px]' : 'text-[16px]'">{{
        $t("articleroute.futures")
      }}</span>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import FujersIcon from "./icons/FujersIcon.vue";

defineProps({
  isCollapsed: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
</script>
