<template>
  <div class="border-b">
    <div
      class="flex justify-between  pb-[18px] px-[16px] pk:px-[10px] text-[#797979] text-[11px]  mobilemini:text-[10px] font-[400]"
    >
      <button
        @click="goToTransactionsMake"
        class="flex flex-col items-center hover:text-[#333] justify-between"
      >
        <ArticleMethodsAddIcon class="svg" />
        {{ $t("articleoperations.replenish") }}
      </button>
      <button
        @click="goToTransactionsTransfer"
        class="flex flex-col items-center hover:text-[#333] justify-between"
      >
        <ArticleMethodsSendIcon class="svg" />
        {{ $t("articleoperations.translation") }}
      </button>
      <button
        class="flex flex-col items-center justify-between hover:text-[#333]"
        @click="goToTransactionsExchange"
      >
        <ArticleMethodsChangeIcon class="svg" />
        {{ $t("articleoperations.exchange") }}
      </button>
      <button
        @click="goToTransactionsWallet"
        class="flex flex-col items-center hover:text-[#333] justify-between"
      >
        <ArticleMethodsActiveIcon class="svg" />
        {{ $t("articleoperations.wallet") }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import ArticleMethodsSendIcon from "../components/icons/ArticleMethodsSendIcon.vue";
import ArticleMethodsAddIcon from "../components/icons/ArticleMethodsAddIcon.vue";
import ArticleMethodsChangeIcon from "../components/icons/ArticleMethodsChangeIcon.vue";
import ArticleMethodsActiveIcon from "../components/icons/ArticleMethodsActiveIcon.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const emit = defineEmits(["navigation"]);

const goToTransactionsWallet = () => {
  router.push({ name: "TransactionsWallet" });
  emit("navigation");
};

const goToTransactionsTransfer = () => {
  router.push({ name: "TransactionsTransfer" });
  emit("navigation");
};

const goToTransactionsExchange = () => {
  router.push({ name: "TransactionsExchange" });
  emit("navigation");
};

const goToTransactionsMake = () => {
  router.push({ name: "TransactionsMake" });
  emit("navigation");
};
</script>

<style scoped>
@media (min-width: 1025px) {
  .svg {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 1024) {
  .svg {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 767px) {
  .svg {
    width: 28px;
    height: 28px;
  }
}
</style>
