import { defineStore } from "pinia";
import { $axios, apiList } from "../api";
import { useCryptoCurrencyStore } from "./cryptoCurrencyStore";
import { useUserStore } from "./userStore";

export const usePostsStore = defineStore("posts", {
  state: () => ({
    myPosts: [],
    allPosts: [],
    loading: false,
    error: null,
    validationErrors: null,
    currentPage: 1,
    itemsPerPage: 10,
    newPost: {
      wantTo: null,
      cryptocurrency: null,
      nationalCurrency: null,
      price: null,
      amount: null,
      minAmount: 500,
      maxAmount: null,
      paymentTime: 15,
      paymentMethods: [],
      comment: "",
    },
    isCreating: false,
    creationTimestamp: null,
    refreshInterval: null,
    isLoaded: false,
    initializePromise: null,
  }),

  actions: {
    initializeStore() {
      if (this.isLoaded) {
        return Promise.resolve(this.allPosts);
      }

      if (this.initializePromise) {
        return this.initializePromise;
      }

      this.initializePromise = this.fetchAllPosts()
        .then((posts) => {
          this.isLoaded = true;
          return posts;
        })
        .finally(() => {
          this.initializePromise = null;
        });

      return this.initializePromise;
    },

    fetchAllPosts() {
      if (this.loading) {
        return (
          this.initializePromise ||
          Promise.reject(new Error("Loading in progress"))
        );
      }

      this.loading = true;
      this.error = null;

      return $axios(apiList.offers.getAllActive)
        .then((response) => {
          this.allPosts = response.data.offers || [];
          return this.allPosts;
        })
        .catch((error) => {
          this.error = "Failed to load posts. Please try again later.";
          console.error("Error fetching all posts:", error);
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    startRefreshInterval(interval, filterFunction) {
      this.stopRefreshInterval();
      if (interval > 0) {
        this.refreshInterval = setInterval(() => {
          this.fetchAllPosts().then(() => {
            if (typeof filterFunction === "function") {
              filterFunction(this.allPosts);
            }
          });
        }, interval);
      }
    },

    stopRefreshInterval() {
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
        this.refreshInterval = null;
      }
    },

    fetchMyPosts() {
      this.loading = true;
      this.error = null;
      const userStore = useUserStore();

      if (!userStore.user) {
        return Promise.reject(new Error("User not authenticated"));
      }

      return $axios({
        ...apiList.offers.getAll,
        params: {
          user_id: userStore.user.id,
        },
      })
        .then((response) => {
          // Проверяем, является ли ответ объектом пагинации
          if (response.data && Array.isArray(response.data.data)) {
            this.myPosts = response.data.data; // Берем массив из поля data
          } else if (response.data && Array.isArray(response.data.offers)) {
            this.myPosts = response.data.offers; // Старая логика
          } else {
            this.myPosts = Array.isArray(response.data) ? response.data : [];
          }
          return this.myPosts;
        })
        .catch((error) => {
          console.error("Error fetching my posts:", error);
          this.error = "Failed to fetch your posts. Please try again later.";
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    canCreateAd(cryptocurrency, type) {
      // Проверяем, имеет ли myPosts свойство data и является ли оно массивом
      const posts = Array.isArray(this.myPosts)
        ? this.myPosts
        : this.myPosts && Array.isArray(this.myPosts.data)
        ? this.myPosts.data
        : [];

      const adsForCrypto = posts.filter(
        (ad) => ad.currency_from_id === cryptocurrency
      );
      const existingAdType = adsForCrypto.find((ad) => ad.type === type);
      return !existingAdType && adsForCrypto.length < 2;
    },

    addPost() {
      if (
        this.isCreating ||
        (this.creationTimestamp && Date.now() - this.creationTimestamp < 5000)
      ) {
        return Promise.reject(
          new Error("Please wait before creating another post")
        );
      }

      if (!this.canCreateAd(this.newPost.cryptocurrency, this.newPost.wantTo)) {
        return Promise.reject(
          new Error(
            "You have reached the maximum number of ads for this cryptocurrency or already have an ad of this type."
          )
        );
      }

      this.isCreating = true;
      this.error = null;
      this.validationErrors = null;

      if (
        !this.newPost.paymentMethods ||
        this.newPost.paymentMethods.length === 0
      ) {
        return Promise.reject(
          new Error("Please select at least one payment method")
        );
      }

      const newPostData = {
        currency_from_id: this.newPost.cryptocurrency,
        currency_to_id: this.newPost.nationalCurrency,
        type: this.newPost.wantTo,
        price: this.newPost.price,
        price_type: "fixed",
        available: this.newPost.amount,
        min_amount: this.newPost.minAmount,
        max_amount: this.newPost.maxAmount,
        pay_time: Number(this.newPost.paymentTime),
        comment: this.newPost.comment,
        payment_methods: this.reformatPaymentMethods(
          this.newPost.paymentMethods
        ),
      };
      console.log("Sending post data:", {
        ...newPostData,
        pay_time_type: typeof newPostData.pay_time,
        original_pay_time: this.newPost.paymentTime,
        original_pay_time_type: typeof this.newPost.paymentTime,
      });

      return $axios({
        url: apiList.offers.create.url,
        method: apiList.offers.create.method,
        data: newPostData,
      })
        .then((response) => {
          const addedPost = response.data.offer || response.data;
          this.myPosts.push(addedPost);
          this.allPosts.push(addedPost);
          this.resetNewPost();
          this.creationTimestamp = Date.now();
          return addedPost;
        })
        .catch((error) => {
          this.handleError(error);
          throw error;
        })
        .finally(() => {
          this.isCreating = false;
        });
    },

    forceRefreshPosts() {
      this.isLoaded = false;
      return this.fetchAllPosts();
    },

    fetchOffersByUserId(userId) {
      this.loading = true;
      this.error = null;
      return $axios({
        url: apiList.offers.getByUserId.url.replace(":userId", userId),
        method: apiList.offers.getByUserId.method,
        params: { is_active: 1 },
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.error = "Failed to fetch offers. Please try again later.";
          console.error("Error fetching offers:", error);
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getCryptocurrencyName(currencyId) {
      const cryptoCurrencyStore = useCryptoCurrencyStore();
      return cryptoCurrencyStore.getCryptocurrencyName(currencyId);
    },

    reformatPaymentMethods(methods) {
      return methods.map((method) => {
        const reformattedMethod = {
          payment_method_id: method.payment_method_id,
          extra_data: {},
        };

        if (method.number) {
          reformattedMethod.extra_data.number = method.number;
        }
        if (method.fio) {
          reformattedMethod.extra_data.fio = method.fio;
        }

        if (method.extra_data) {
          reformattedMethod.extra_data = {
            ...reformattedMethod.extra_data,
            ...method.extra_data,
          };
        }

        if (Object.keys(reformattedMethod.extra_data).length === 0) {
          delete reformattedMethod.extra_data;
        }

        return reformattedMethod;
      });
    },

    setNewPostField(field, value) {
      if (field !== "paymentMethods") {
        this.newPost[field] = value;
      } else {
        if (Array.isArray(value) && value.length > 0) {
          this.newPost[field] = value.map((method) => ({
            payment_method_id: Number(method.payment_method_id),
            type: method.type || undefined,
            number: method.number || undefined,
            fio: method.fio || undefined,
            extra_data:
              Object.keys(method.extra_data || {}).length > 0
                ? method.extra_data
                : undefined,
          }));
        }
      }
    },

    updatePost(updatedPost) {
      return $axios({
        ...apiList.offers.update,
        url: apiList.offers.update.url.replace(":id", updatedPost.id),
        data: updatedPost,
      })
        .then((response) => {
          const updated = response.data.offer || response.data;
          this.updatePostInArrays(updated);
          return updated;
        })
        .catch((error) => {
          console.error("Error updating post:", error);
          throw error;
        });
    },

    getPostById(id) {
      if (!id) {
        return Promise.reject(new Error("Invalid post ID"));
      }

      const userStore = useUserStore();

      return userStore
        .fetchUser()
        .then((user) => {
          if (!user) {
            throw new Error("User is not authenticated");
          }
          return $axios({
            ...apiList.offers.getById,
            url: apiList.offers.getById.url.replace(":id", id),
          });
        })
        .then((response) => {
          if (!response.data || !response.data.offer) {
            throw new Error("Invalid response format");
          }
          return response.data;
        })
        .catch((error) => {
          console.error("Error fetching post by ID:", error);
          if (error.response) {
            if (error.response.status === 403) {
              throw new Error("You are not authorized to view this post");
            } else if (error.response.status === 404) {
              throw new Error("Post not found");
            } else if (error.response.status === 500) {
              throw new Error("Server error. Please try again later.");
            }
          }
          throw error;
        });
    },

    deletePost(id) {
      return $axios({
        ...apiList.offers.delete,
        url: apiList.offers.delete.url.replace(":id", id),
      })
        .then(() => {
          this.removePostFromArrays(id);
        })
        .catch((error) => {
          const errorDetails = {
            message: error.response?.data?.message,
            exception: error.response?.data?.exception,
            file: error.response?.data?.file,
            line: error.response?.data?.line,
            trace: error.response?.data?.trace,
          };

          throw error;
        });
    },

    togglePostVisibility(postId, isActive) {
      return $axios({
        ...apiList.offers.update,
        url: apiList.offers.update.url.replace(":id", postId),
        data: { is_active: isActive },
      })
        .then((response) => {
          const updatedPost = response.data.offer || response.data;
          this.updatePostInArrays(updatedPost);
          return updatedPost;
        })
        .catch((error) => {
          console.error("Error toggling post visibility:", error);
          throw error;
        });
    },

    toggleAllPostsVisibility(isActive) {
      const updatePromises = this.myPosts.map((post) =>
        this.togglePostVisibility(post.id, isActive)
      );

      return Promise.all(updatePromises)
        .then(() => {
          console.log(`All posts visibility toggled to ${isActive}`);
        })
        .catch((error) => {
          console.error("Error toggling all posts visibility:", error);
          throw error;
        });
    },

    updatePostInArrays(updatedPost) {
      const updateInArray = (array) => {
        const index = array.findIndex((post) => post.id === updatedPost.id);
        if (index !== -1) {
          array[index] = { ...array[index], ...updatedPost };
        }
      };

      updateInArray(this.myPosts);
      updateInArray(this.allPosts);
    },

    setCurrentPage(page) {
      this.currentPage = page;
    },

    handleError(error) {
      if (error.response) {
        if (error.response.status === 422) {
          this.validationErrors = error.response.data.errors;
          this.error =
            "Validation failed. Please check the form and try again.";
        } else {
          this.error =
            error.response.data.message ||
            "An error occurred while processing your request.";
        }
      } else if (error.request) {
        this.error = "No response received from the server. Please try again.";
      } else {
        this.error =
          "An error occurred while setting up the request. Please try again.";
      }
    },

    resetNewPost() {
      this.newPost = {
        wantTo: null,
        cryptocurrency: null,
        nationalCurrency: null,
        price: null,
        amount: null,
        minAmount: 500,
        maxAmount: null,
        paymentTime: 15,
        paymentMethods: [],
        comment: "",
      };
    },

    removePostFromArrays(id) {
      this.myPosts = this.myPosts.filter((post) => post.id !== id);
      this.allPosts = this.allPosts.filter((post) => post.id !== id);
    },
  },

  getters: {
    getPaginatedPosts() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.allPosts.slice(start, end);
    },

    totalPages() {
      return Math.ceil(this.allPosts.length / this.itemsPerPage);
    },
  },
});
