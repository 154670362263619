const messages = {
  ru: {
    articlebalance: {
      hello: "Здравствуйте,",
      guest: "Гость",
      balance: "Общий баланс",
      balanceShort: "Баланс",
      status: "Статус:",
      vip: "Купить VIP",
      statuses: {
        newer: "Новичок",
        merchant: "Мерчант",
      },
    },
    articleoperations: {
      replenish: "Пополнить",
      translation: "Перевести",
      exchange: "Обмен",
      wallet: "Кошелёк",
    },
    articleroute: {
      headerp2p: "P2P Маркет",
      p2p: "P2P Маркет",
      myads: "Мои объявления",
      mydeal: "Мои сделки",
      pay: "Настройки оплаты",
      tablep2p: "Таблица P2P лидеров",
      spot: "Спот",
      stockMarket: "Биржевой рынок",
      skoro: "Скоро",
      futures: "Фьючерсы",
      farming: "Farming",
      dailyTasks: "Ежедневные задания",
      staking: "Стейкинг",
    },
  },
  en: {
    articlebalance: {
      hello: "Hello,",
      guest: "Guest",
      balance: "Total Balance",
      balanceShort: "Balance",
      status: "Status:",
      vip: "Buy VIP",
      statuses: {
        newer: "Newcomer",
        merchant: "Merchant",
      },
    },
    articleoperations: {
      replenish: "Deposit",
      translation: "Transfer",
      exchange: "Exchange",
      wallet: "Wallet",
    },
    articleroute: {
      headerp2p: "P2P Trading",
      p2p: "P2P Market",
      myads: "My Ads",
      mydeal: "My Deals",
      pay: "Payment Settings",
      tablep2p: "P2P Leaders Table",
      spot: "Spot",
      stockMarket: "Stock Market",
      skoro: "Coming soon",
      futures: "Futures",
      farming: "Farming",
      dailyTasks: "Daily Tasks",
      staking: "Staking",
    },
  },
  zh: {
    articlebalance: {
      hello: "您好，",
      guest: "访客",
      balance: "总余额",
      balanceShort: "余额",
      status: "状态：",
      vip: "购买 VIP",
      statuses: {
        newer: "新手",
        merchant: "商家",
      },
    },
    articleoperations: {
      replenish: "补充",
      translation: "转移",
      exchange: "交换",
      wallet: "钱包",
    },
    articleroute: {
      headerp2p: "P2P 交易",
      p2p: "P2P 市场",
      myads: "我的广告",
      mydeal: "我的交易",
      pay: "支付设置",
      tablep2p: "P2P 领导者榜",
      spot: "现货",
      stockMarket: "证券市场",
      skoro: "即将推出",
      futures: "期货",
      farming: "挖矿",
      dailyTasks: "每日任务",
      staking: "质押",
    },
  },
  ja: {
    articlebalance: {
      hello: "こんにちは、",
      guest: "ゲスト",
      balance: "総残高",
      balanceShort: "残高",
      status: "ステータス：",
      vip: "VIP購入",
      statuses: {
        newer: "新規",
        merchant: "マーチャント",
      },
    },
    articleoperations: {
      replenish: "補充",
      translation: "転送",
      exchange: "交換",
      wallet: "財布",
    },
    articleroute: {
      headerp2p: "P2P取引",
      p2p: "P2Pマーケット",
      myads: "マイ広告",
      mydeal: "マイ取引",
      pay: "支払い設定",
      tablep2p: "P2Pリーダーボード",
      spot: "スポット",
      stockMarket: "取引所市場",
      skoro: "近日公開",
      futures: "先物",
      farming: "ファーミング",
      dailyTasks: "デイリータスク",
      staking: "ステーキング",
    },
  },
};

export default messages;
