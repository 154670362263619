<template>
  <div class="pt-[15px] pb-[10px] relative dropdown-container">
    <h2
      @mouseenter="showMenu = true"
      class="flex items-center justify-start mb-[5px] text-[16px] font-[300] pk:text-[12px] text-nowrap text-[#797979]/50 pl-[45px] pk:pl-[30px] cursor-pointer"
    >
      <span class="flex items-center">
        <ArticleMarketIcon class="w-[20px] h-[17px] mr-1.5" />
        {{ $t("articleroute.headerp2p") }}
      </span>
    </h2>

    <ul
      v-show="showMenu"
      @mouseleave="showMenu = false"
      class="flex flex-col items-start justify-between text-[#000] font-[300] text-[14px] pk:text-[12px] absolute left-0 w-full py-2 transition-all duration-300 ease-in-out"
    >
      <li class="w-full">
        <MarketP2P
          class="flex pl-[65px] pk:pl-[40px] w-full py-[3px]"
          :class="getItemClass('main')"
          @click="navigateTo('main')"
        />
      </li>
      <li class="w-full">
        <MarketMy
          class="flex pl-[65px] pk:pl-[40px] w-full py-[3px]"
          :class="getItemClass('myAds')"
          @click="navigateTo('myAds')"
        />
      </li>
      <li class="w-full">
        <MarketHistory
          class="flex pl-[65px] pk:pl-[40px] w-full py-[3px]"
          :class="getItemClass('history')"
          @click="navigateTo('history')"
        />
      </li>
      <li class="w-full">
        <MarketSetup
          class="flex pl-[65px] pk:pl-[40px] w-full py-[3px]"
          :class="getItemClass('settings')"
          @click="navigateTo('settings')"
        />
      </li>
      <li class="flex items-center">
        <TableLeads
          class="flex pl-[65px] pk:pl-[40px] py-[3px] w-full"
          :class="getItemClass('', true)"
          @click="() => {}"
        />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useNavigationStore } from "../stores/marketStore";
import MarketMy from "./MarketMy.vue";
import MarketSetup from "./MarketSetup.vue";
import MarketHistory from "./MarketHistory.vue";
import TableLeads from "./TableLeads.vue";
import MarketP2P from "./MarketP2P.vue";
import ArticleMarketIcon from "./icons/ArticleMarketIcon.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const navigationStore = useNavigationStore();
const showMenu = ref(false);

const getItemClass = (name, isComingSoon = false) => [
  "transition duration-300 ease-in-out",
  isComingSoon
    ? "text-[#797979] cursor-not-allowed"
    : route.name === name
    ? " bg-gray-100"
    : " hover:bg-gray-100",
];

const navigateTo = (name, isComingSoon = false) => {
  if (!isComingSoon) {
    navigationStore.setView(name);
    showMenu.value = false; // Скрыть меню после выбора
    router.push({ name: name }).catch((err) => {
      if (err.name !== "NavigationDuplicated") {
        throw err;
      }
    });
  }
};
</script>

<style scoped>
.dropdown-container {
  position: relative;
}
</style>
