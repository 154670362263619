<template>
  <svg
    width="15"
    height="21"
    viewBox="0 0 15 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.42913 20.2422C1.23969 20.2422 1.05801 20.1669 0.924053 20.033C0.790099 19.899 0.714844 19.7173 0.714844 19.5279V4.5279C0.714844 4.33846 0.790099 4.15678 0.924053 4.02283C1.05801 3.88887 1.23969 3.81362 1.42913 3.81362C1.61857 3.81362 1.80025 3.88887 1.93421 4.02283C2.06816 4.15678 2.14342 4.33846 2.14342 4.5279V19.5279C2.14342 19.7173 2.06816 19.899 1.93421 20.033C1.80025 20.1669 1.61857 20.2422 1.42913 20.2422ZM7.14342 18.0993C6.95398 18.0993 6.77229 18.0241 6.63834 17.8901C6.50438 17.7562 6.42913 17.5745 6.42913 17.385V0.956473C6.42913 0.767033 6.50438 0.585352 6.63834 0.451397C6.77229 0.317443 6.95398 0.242188 7.14342 0.242188C7.33286 0.242188 7.51454 0.317443 7.64849 0.451397C7.78245 0.585352 7.8577 0.767033 7.8577 0.956473V17.385C7.8577 17.5745 7.78245 17.7562 7.64849 17.8901C7.51454 18.0241 7.33286 18.0993 7.14342 18.0993ZM12.8577 13.0993C12.6683 13.0993 12.4866 13.0241 12.3526 12.8901C12.2187 12.7562 12.1434 12.5745 12.1434 12.385V2.38504C12.1434 2.1956 12.2187 2.01392 12.3526 1.87997C12.4866 1.74601 12.6683 1.67076 12.8577 1.67076C13.0471 1.67076 13.2288 1.74601 13.3628 1.87997C13.4967 2.01392 13.572 2.1956 13.572 2.38504V12.385C13.572 12.5745 13.4967 12.7562 13.3628 12.8901C13.2288 13.0241 13.0471 13.0993 12.8577 13.0993Z"
      fill="#797979"
      fill-opacity="0.5"
    />
    <path
      d="M2.14286 8.81361H0.714286C0.319797 8.81361 0 9.13341 0 9.5279V17.385C0 17.7795 0.319797 18.0993 0.714286 18.0993H2.14286C2.53735 18.0993 2.85714 17.7795 2.85714 17.385V9.5279C2.85714 9.13341 2.53735 8.81361 2.14286 8.81361Z"
      fill="#4ADE80"
    />
    <path
      d="M7.8577 3.81361H6.42913C6.03464 3.81361 5.71484 4.13341 5.71484 4.5279V8.09933C5.71484 8.49382 6.03464 8.81361 6.42913 8.81361H7.8577C8.25219 8.81361 8.57199 8.49382 8.57199 8.09933V4.5279C8.57199 4.13341 8.25219 3.81361 7.8577 3.81361Z"
      fill="#FF6663"
    />
    <path
      d="M13.5706 8.09933H12.142C11.7475 8.09933 11.4277 8.41913 11.4277 8.81362V9.52791C11.4277 9.9224 11.7475 10.2422 12.142 10.2422H13.5706C13.9651 10.2422 14.2849 9.9224 14.2849 9.52791V8.81362C14.2849 8.41913 13.9651 8.09933 13.5706 8.09933Z"
      fill="#FF6663"
    />
  </svg>
</template>
