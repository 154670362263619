import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "axios";
import { apiList } from "../api";
import { useUserStore } from "./userStore";
export { apiList };
import { useRouter } from "vue-router";

export const $axios = axios.create({
  baseURL: "https://backend.umbrellax.io/api",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

$axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

$axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Создаем store только в случае ошибки
      const authStore = useAuthStore();
      // Убираем круговую зависимость
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
    return Promise.reject(error);
  }
);

export const useAuthStore = defineStore("auth", () => {
  const userStore = useUserStore();
  const router = useRouter();
  const user = ref(null);
  const isLoading = ref(false);
  const token = ref(localStorage.getItem("token"));
  const isAuthenticated = ref(!!token.value);

  const initializeAuth = () => {
    return new Promise((resolve) => {
      const storedToken = localStorage.getItem("token");
      const storedUser = localStorage.getItem("user");
      if (storedToken && storedUser) {
        token.value = storedToken;
        user.value = JSON.parse(storedUser);
        isAuthenticated.value = true;
      }
      resolve();
    });
  };

  const clearToken = () => {
    token.value = null;
    localStorage.removeItem("token");
    isAuthenticated.value = false;
    delete $axios.defaults.headers.common["Authorization"];
  };

  const setUser = (userData, socialProvider = null) => {
    if (socialProvider === "telegram" && userData.username) {
      userData.username = userData.username;
    }
    user.value = userData;
    userStore.updateUser(userData);
    isAuthenticated.value = true;
    localStorage.setItem("user", JSON.stringify(userData));
  };

  const setToken = (newToken) => {
    token.value = newToken;
    localStorage.setItem("token", newToken);
    $axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
  };

  const clearAuthData = () => {
    user.value = null;
    token.value = null;
    isAuthenticated.value = false;
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    delete $axios.defaults.headers.common["Authorization"];
  };

  const logout = async () => {
    try {
      // First clear all local data before API call
      clearAuthData();
      await userStore.handleLogout();

      // Attempt API call, but don't wait for it
      $axios({
        url: apiList.auth.logout.url,
        method: apiList.auth.logout.method,
      }).catch((error) => {
        console.error("Logout API error (non-blocking):", error);
      });

      // Force navigation to landing
      await router.push("/landing");

      // Double check authentication states
      isAuthenticated.value = false;
      await userStore.setAuthenticated(false);
    } catch (error) {
      console.error("Logout error:", error);
      // Ensure data is cleared even if navigation fails
      clearAuthData();
      await userStore.handleLogout();
      throw error;
    }
  };

  const register = (formData) => {
    isLoading.value = true;

    const dataToSend = { ...formData };
    if (!dataToSend.ref_code || dataToSend.ref_code.trim() === "") {
      delete dataToSend.ref_code;
    }

    return new Promise((resolve, reject) => {
      $axios({
        url: apiList.auth.register.url,
        method: apiList.auth.register.method,
        data: dataToSend,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response?.data || error);
        })
        .finally(() => {
          isLoading.value = false;
        });
    });
  };

  const verifyEmail = (email, code) => {
    console.log("Verifying email with:", { email, code });

    return new Promise((resolve, reject) => {
      if (!email || !code) {
        console.error("Missing verification data:", { email, code });
        return reject(new Error("Email and code are required"));
      }

      $axios({
        url: apiList.auth.verify.url,
        method: "POST",
        params: {
          email,
          code,
        },

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log("Verification response:", response);

          if (response.data.token) {
            setToken(response.data.token);
            setUser(response.data.user);
          }

          resolve(response.data);
        })
        .catch((error) => {
          console.error("Verification error:", error.response || error);
          reject(error);
        });
    });
  };

  const login = (loginInput, password) => {
    isLoading.value = true;

    const params = new URLSearchParams();
    params.append("login", loginInput);
    params.append("password", password);

    return new Promise((resolve, reject) => {
      $axios({
        url: apiList.auth.login.url,
        method: apiList.auth.login.method,
        params: params,
      })
        .then((response) => {
          if (
            response.data.message === "Verification code sent to your email."
          ) {
            resolve({
              message: response.data.message,
              email: response.data.email || loginInput,
            });
            return;
          }

          const { access_token: newToken, user: userData } = response.data;
          if (newToken && userData) {
            setToken(newToken);
            setUser(userData);
            resolve({
              success: true,
              user: userData,
              token: newToken,
            });
          } else {
            reject(new Error("Некорректный ответ сервера"));
          }
        })
        .catch((error) => {
          console.error("Login error:", error.response?.data || error);
          reject(error.response?.data || error);
        })
        .finally(() => {
          isLoading.value = false;
        });
    });
  };

  const verifyLogin = (email, password, code) => {
    isLoading.value = true;

    if (!email || !password || !code) {
      return Promise.reject({
        message: "Missing required parameters",
      });
    }

    const params = new URLSearchParams();
    params.append("email", email);
    params.append("password", password);
    params.append("code", code);

    return new Promise((resolve, reject) => {
      $axios({
        url: apiList.auth.loginVerify.url,
        method: apiList.auth.loginVerify.method,
        params: params,
      })
        .then((response) => {
          const { access_token: newToken, user: userData } = response.data;

          if (newToken && userData) {
            setToken(newToken);
            setUser(userData);
            resolve({
              success: true,
              user: userData,
              token: newToken,
            });
          } else {
            reject(new Error("Некорректный ответ сервера"));
          }
        })
        .catch((error) => {
          console.error("Verify login error:", error.response?.data || error);
          reject(error.response?.data || error);
        })
        .finally(() => {
          isLoading.value = false;
        });
    });
  };

  const resendVerificationCode = (email) => {
    return new Promise((resolve, reject) => {
      $axios({
        url: `${apiList.auth.verify.url}`,
        method: "GET",
        params: { email },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response?.data || error);
        });
    });
  };

  const resetPassword = (email, code, password, password_confirmation) => {
    return new Promise((resolve, reject) => {
      $axios
        .post(apiList.auth.resetPassword.url, {
          email,
          code,
          password,
          password_confirmation,
        })
        .then((response) => {
          if (response.data && response.data.message) {
            resolve(response.data.message);
          } else {
            reject(new Error("No message received from server"));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const authenticateWithTelegram = async (params, logOperation) => {
    isLoading.value = true;

    try {
      // Логируем начало процесса
      if (logOperation) {
        logOperation("Auth Request", "pending");
      }

      // Формируем параметры запроса - нужно передать hash из Telegram WebApp
      const authData = {
        auth_date: params.auth_date,
        first_name: params.first_name || "",
        hash: params.hash, // Передаем оригинальный хеш из Telegram
        id: params.id,
        username: params.username || "",
      };

      // Выполняем запрос на бэкенд
      const response = await $axios({
        url: apiList.auth.telegram.url,
        method: apiList.auth.telegram.method,
        params: authData,
      });

      if (response.data?.token) {
        setToken(response.data.token);
        setUser(response.data.user, "telegram");

        if (logOperation) {
          logOperation("Authorization", "success");
        }

        return {
          success: true,
          user: response.data.user,
          token: response.data.token,
        };
      }

      throw new Error("Неверный ответ сервера");
    } catch (error) {
      if (logOperation) {
        logOperation("Telegram Authentication", "error", null, error.message);
      }

      clearAuthData();
      throw error;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    user,
    isLoading,
    token,
    isAuthenticated,
    logout,
    register,
    login,
    verifyLogin,
    initializeAuth,
    clearToken,
    resetPassword,
    verifyEmail,
    setUser,
    setToken,
    resendVerificationCode,
    authenticateWithTelegram,
  };
});
