<template>
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_dd_3031_11917)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 4.75C4 4.55109 4.07902 4.36032 4.21967 4.21967C4.36032 4.07902 4.55109 4 4.75 4H21.25C21.4489 4 21.6397 4.07902 21.7803 4.21967C21.921 4.36032 22 4.55109 22 4.75C22 4.94891 21.921 5.13968 21.7803 5.28033C21.6397 5.42098 21.4489 5.5 21.25 5.5H4.75C4.55109 5.5 4.36032 5.42098 4.21967 5.28033C4.07902 5.13968 4 4.94891 4 4.75ZM4 10C4 9.80109 4.07902 9.61032 4.21967 9.46967C4.36032 9.32902 4.55109 9.25 4.75 9.25H21.25C21.4489 9.25 21.6397 9.32902 21.7803 9.46967C21.921 9.61032 22 9.80109 22 10C22 10.1989 21.921 10.3897 21.7803 10.5303C21.6397 10.671 21.4489 10.75 21.25 10.75H4.75C4.55109 10.75 4.36032 10.671 4.21967 10.5303C4.07902 10.3897 4 10.1989 4 10ZM4 15.25C4 15.0511 4.07902 14.8603 4.21967 14.7197C4.36032 14.579 4.55109 14.5 4.75 14.5H21.25C21.4489 14.5 21.6397 14.579 21.7803 14.7197C21.921 14.8603 22 15.0511 22 15.25C22 15.4489 21.921 15.6397 21.7803 15.7803C21.6397 15.921 21.4489 16 21.25 16H4.75C4.55109 16 4.36032 15.921 4.21967 15.7803C4.07902 15.6397 4 15.4489 4 15.25Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_3031_11917"
        x="0"
        y="0"
        width="26"
        height="20"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3031_11917"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_3031_11917"
          result="effect2_dropShadow_3031_11917"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_3031_11917"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
