<template>
  
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 3.0627C0 1.50498 1.26279 0.242188 2.82051 0.242188H17.1795C18.7372 0.242188 20 1.50498 20 3.0627V17.4217C20 18.9794 18.7372 20.2422 17.1795 20.2422H2.82051C1.26279 20.2422 0 18.9794 0 17.4217V3.0627ZM2.82051 1.78065C2.11245 1.78065 1.53846 2.35464 1.53846 3.0627V17.4217C1.53846 18.1298 2.11245 18.7037 2.82051 18.7037H17.1795C17.8876 18.7037 18.4615 18.1298 18.4615 17.4217V3.0627C18.4615 2.35464 17.8876 1.78065 17.1795 1.78065H2.82051Z"
      fill="#F1CF96"
    />
    <path
      d="M3.53125 6.13962C3.53125 5.71479 3.87565 5.37039 4.30048 5.37039H14.5569C14.9817 5.37039 15.3261 5.71479 15.3261 6.13962C15.3261 6.56445 14.9817 6.90885 14.5569 6.90885H4.30048C3.87565 6.90885 3.53125 6.56445 3.53125 6.13962Z"
      fill="#4ADE80"
    />
    <path
      d="M10.4548 9.47293C10.03 9.47293 9.68555 9.81734 9.68555 10.2422C9.68555 10.667 10.03 11.0114 10.4548 11.0114H14.5573C14.9822 11.0114 15.3266 10.667 15.3266 10.2422C15.3266 9.81734 14.9822 9.47293 14.5573 9.47293H10.4548Z"
      fill="#4ADE80"
    />
    <path
      d="M7.77861 14.7933C7.47821 15.0937 6.99115 15.0937 6.69075 14.7933L4.6395 12.742C4.33909 12.4416 4.33909 11.9546 4.6395 11.6542L6.69075 9.60292C6.99115 9.30251 7.47821 9.30251 7.77861 9.60292C8.07901 9.90333 8.07901 10.3903 7.77861 10.6907L6.27125 12.1981L7.77861 13.7055C8.07901 14.0059 8.07901 14.4929 7.77861 14.7933Z"
      fill="#4ADE80"
    />
    <path
      d="M10.4548 13.5755C10.03 13.5755 9.68555 13.9199 9.68555 14.3447C9.68555 14.7696 10.03 15.114 10.4548 15.114H14.5573C14.9822 15.114 15.3266 14.7696 15.3266 14.3447C15.3266 13.9199 14.9822 13.5755 14.5573 13.5755H10.4548Z"
      fill="#4ADE80"
    />
  </svg>

</template>
