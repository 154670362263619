<template>
  <button
    class="flex items-center gap-[5px]"
    :class="isCollapsed ? 'justify-center flex-col' : 'justify-start'"
  >
    <DailyTaskIcon
      :class="isCollapsed ? 'w-[42px] h-[42px]' : 'w-[20px] h-[20px] mr-1.5'"
    />
    <span :class="isCollapsed ? 'text-[12px]' : ''">{{
      $t("articleroute.dailyTasks")
    }}</span>
  </button>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import DailyTaskIcon from "./icons/DailyTaskIcon.vue";

defineProps({
  isCollapsed: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
</script>
