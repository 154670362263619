import axios from "axios";

const API_URL = "https://backend.umbrellax.io/api";

const $axios = axios.create({
  baseURL: API_URL,
});

$axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

$axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
    return Promise.reject(error);
  }
);

const apiList = {
  auth: {
    login: {
      url: "/auth/login",
      method: "POST",
    },
    loginVerify: {
      url: "/auth/login/verify",
      method: "POST",
    },
    telegram: {
      url: "/auth/login/bot",
      method: "POST",
    },
    socialite: {
      google: {
        url: `/auth/login/socialite/google`,
        callbackMethod: "POST",
        getRedirectMethod: "GET",
      },
      telegram: {
        url: "/auth/login/socialite/telegram",
        method: "POST",
      },
    },
    register: {
      url: "/auth/register",
      method: "POST",
    },
    logout: {
      url: "/auth/logout",
      method: "POST",
    },
    getUser: {
      url: "/auth/user",
      method: "GET",
    },
    twoFa: {
      getKey: {
        url: "/auth/2fa",
        method: "GET",
      },
      login: {
        url: "/auth/2fa",
        method: "POST",
      },
    },
    verify: {
      url: "/auth/verify-email",
      method: "POST",
    },
  },
  users: {
    getById: {
      url: "/users/:id/profile",
      method: "GET",
    },
    updateProfile: {
      url: "/users/:id/profile",
      method: "PUT",
    },
  },
  paymentMethods: {
    getAll: {
      url: "/payment-methods",
      method: "GET",
    },
    getAllUser: {
      url: "/users/payment-methods",
      method: "GET",
    },
    getById: {
      url: "/users/payment-methods/:id",
      method: "GET",
    },
    add: {
      url: "/users/payment-methods",
      method: "POST",
    },
    update: {
      url: "/users/payment-methods/:id",
      method: "PUT",
    },
    delete: {
      url: "/users/payment-methods/:id",
      method: "DELETE",
    },
  },
  offers: {
    create: {
      url: "/offers",
      method: "POST",
    },
    getAll: {
      url: "/offers",
      method: "GET",
    },
    getAllActive: {
      url: "/offers?is_active=1",
      method: "GET",
    },
    getAllInactive: {
      url: "/offers?is_active=0",
      method: "GET",
    },
    getByUserId: {
      url: "/offers?user_id=:userId",
      method: "GET",
    },
    getById: {
      url: "/offers/:id",
      method: "GET",
    },
    update: {
      url: "/offers/:id",
      method: "PUT",
    },
    delete: {
      url: "/offers/:id",
      method: "DELETE",
    },
  },
  orders: {
    create: {
      url: "/orders",
      method: "POST",
    },
    getAll: {
      url: "/orders",
      method: "GET",
    },
    getById: {
      url: "/orders/:id",
      method: "GET",
    },
    update: {
      url: "/orders/:id",
      method: "PUT",
    },
    delete: {
      url: "/orders/:id",
      method: "DELETE",
    },
  },
  disputes: {
    create: {
      url: "/disputes",
      method: "POST",
    },
  },
  messages: {
    getByOrderId: {
      url: "/messages/order/{id}",
      method: "GET",
    },
    send: {
      url: "/messages",
      method: "POST",
    },
  },
  currencies: {
    getAll: {
      url: "/currencies",
      method: "GET",
    },
  },
  files: {
    upload: {
      url: "/files",
      method: "POST",
    },
    delete: {
      url: "/files/:id",
      method: "DELETE",
    },
  },
  transactions: {
    exchange: {
      url: "/transactions/exchange",
      method: "POST",
    },
    move: {
      url: "/transactions/move",
      method: "POST",
    },
    withdrawal: {
      url: "/transactions/withdrawal",
      method: "POST",
    },
    getAll: {
      url: "/transactions",
      method: "GET",
    },
    getById: {
      url: "/transactions/:id",
      method: "GET",
    },
  },
  kyc: {
    create: {
      url: "/kyc-applications",
      method: "POST",
    },
    update: {
      url: "/kyc-applications/{id}",
      method: "PUT",
    },
    get: {
      url: "/kyc-applications/user/{id}",
      method: "GET",
    },
  },
  rates: {
    getActual: {
      url: "/rates",
      method: "GET",
    },
  },
  reviews: {
    create: {
      url: "/reviews",
      method: "POST",
    },

    update: {
      url: "/reviews/:id",
      method: "PUT",
    },
  },
  chats: {
    getAll: {
      url: "/chats",
      method: "GET",
    },
    getUserChats: {
      url: "/chats/user",
      method: "GET",
    },
    getPersonalChat: {
      url: "/chats/personal/:userId",
      method: "GET",
    },
    getById: {
      url: "/chats/:id",
      method: "GET",
    },
    create: {
      url: "/chats",
      method: "POST",
    },
    update: {
      url: "/chats/:id",
      method: "PUT",
    },
    join: {
      url: "/chats/:id",
      method: "POST",
    },
    delete: {
      url: "/chats/:id",
      method: "DELETE",
    },
    leave: {
      url: "/chats/:id",
      method: "DELETE",
    },
  },
  chatMessages: {
    createInGroupChat: {
      url: "/chat-messages/chat/:chatId",
      method: "POST",
    },
    createInPersonalChat: {
      url: "/chat-messages/user/:userId",
      method: "POST",
    },
    update: {
      url: "/chat-messages/:id",
      method: "PUT",
    },
    read: {
      url: "/chat-messages/:id/read",
      method: "PUT",
    },
    delete: {
      url: "/chat-messages/:id",
      method: "DELETE",
    },
  },
};

export { $axios, apiList };
