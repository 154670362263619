<template>
  <div class="pt-[15px] pb-[10px]">
    <h2
      class="flex items-center justify-start mb-[5px] pl-[45px] text-[16px] font-[300] pk:text-[12px] pk:pl-[30px] text-nowrap text-[#797979]/50"
    >
      <span class="flex items-center">
        <ArticleGameIcon class="w-6 h-6 mr-1.5" />
        {{ $t("articleroute.farming") }}
      </span>
    </h2>
    <ul
      class="flex flex-col items-start justify-between text-[#000] font-[300] text-[14px] pk:text-[12px]"
    >
      <!-- <li class="flex items-center w-full">
        <ArticleGameLiqu
          class="flex pl-[65px] pk:pl-[40px] py-[3px] w-full"
          :class="getItemClass('game')"
          @click="navigateTo('game')"
        />
      </li> -->
      <li class="flex items-center w-full">
        <ArticleGameLoot
          class="flex pl-[65px] pk:pl-[40px] py-[3px] w-full"
          :class="getItemClass('dailyTasks')"
          @click="navigateTo('dailyTasks')"
        />
      </li>

      <!-- <li class="flex items-center">
        <ArticleGameUmbrella
          class=" max-[1439px]:mb-[4px] max-[767px]:mb-[4px] w-full pl-[65px] max-[1439px]:pl-[30px] max-[767px]:!pl-[50px] cursor-not-allowed text-[#797979] transition duration-300 ease-in-out"
        />
      </li>
      <li class="flex items-center">
        <ArticleGameAirdrop
          class=" max-[1439px]:mb-[4px] max-[767px]:mb-[4px] w-full pl-[65px] max-[1439px]:pl-[30px] max-[767px]:!pl-[50px] cursor-not-allowed text-[#797979] transition duration-300 ease-in-out"
        />
      </li> -->
      <li class="flex items-center w-full">
        <ArticleStacking
          class="flex pl-[65px] pk:pl-[40px] py-[3px] w-full"
          :class="getItemClass('Staking')"
          @click="navigateTo('Staking')"
        />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { useNavigationStore } from "../stores/marketStore";
import ArticleGameLiqu from "./ArticleGameLiqu.vue";
import ArticleGameLoot from "./ArticleGameLoot.vue";
import ArticleStacking from "./ArticleStacking.vue";
import ArticleGameUmbrella from "./ArticleGameUmbrella.vue";
import ArticleGameIcon from "./icons/ArticleGameIcon.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const navigationStore = useNavigationStore();

const getItemClass = (name, isComingSoon = false) => [
  "transition duration-300 ease-in-out",
  isComingSoon
    ? "text-[#797979] cursor-not-allowed"
    : route.name === name
    ? "bg-gray-100"
    : "hover:bg-gray-100",
];

const navigateTo = (name, isComingSoon = false) => {
  if (!isComingSoon) {
    navigationStore.setView(name);
    router.push({ name: name }).catch((err) => {
      if (err.name !== "NavigationDuplicated") {
        throw err;
      }
    });
  }
};
</script>
