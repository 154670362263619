<template>
  <div
    class="fixed inset-0 bg-gray-900 bg-opacity-50 z-[9999] flex justify-center items-center overflow-y-auto"
  >
    <div
      class="flex flex-col bg-white rounded-lg w-4/5 mobile:w-[100%] mobile:h-full mobile:max-w-full mobile:rounded-none max-w-[414px] absolute shadow-in top-1 mobile:top-[unset] mobile:items-center"
    >
      <ModalHeader @close="close" class="mobile:rounded-none w-[100%]" />

      <div
        class="flex flex-col items-center py-2 px-[57px] mobile:px-4 overflow-y-auto custom-scrollbar mobile:max-w-[360px]"
      >
        <h2
          class="text-[16px] font-[500] mb-[6px] pt-3 mobile:pt-0 leading-[100%] text-black"
        >
          {{ $t("auth.signUp.title") }}
        </h2>
        <p
          class="text-[10px] font-[500] mobile:text-[10px] text-[#797979] mb-2"
        >
          {{ $t("auth.signUp.haveAccount") }}
          <a
            href="#"
            @click.prevent="openLoginForm"
            class="relative bg-gradient-to-l from-[#6198fa] to-[#ff1dd9] inline-block text-transparent bg-clip-text group"
          >
            {{ $t("auth.signUp.login") }}
            <span
              class="absolute left-0 bottom-0 w-full h-[1px] bg-gradient-to-l from-[#6198fa] to-[#ff1dd9] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
            ></span>
          </a>
        </p>
        <form
          @submit.prevent="handleSubmit"
          autocomplete="on"
          class="flex items-center flex-col text-[#797979]"
        >
          <div class="flex flex-col mb-2 mobile:mb-1 w-full">
            <input
              v-model="formData.username"
              type="text"
              id="username"
              required
              autocomplete="username"
              class="w-full p-2 pl-4 mobile:py-[6px] text-sm mobile:text-[11px] shadow-in rounded-lg text-[#797979] focus:outline-none focus:ring-1 focus:ring-[#4ADE80]"
              :placeholder="$t('auth.signUp.username')"
            />
            <p
              v-if="errors.username"
              class="flex items-center gap-1 text-[10px] text-[#FF9387] mobile:text-[9px] mt-1 mobile:mt-[0] leading-none"
            >
              {{
                Array.isArray(errors.username)
                  ? errors.username[0]
                  : errors.username
              }}
            </p>
          </div>

          <div class="flex flex-col mb-2 mobile:mb-1 w-full">
            <div class="relative">
              <input
                :value="emailInput"
                @input="handleEmailInput"
                @focus="showSuggestions = true"
                @blur="handleEmailBlur"
                type="email"
                id="email"
                required
                autocomplete="email"
                class="w-full p-2 pl-4 mobile:py-[6px] text-sm mobile:text-[11px] shadow-in rounded-lg text-[#797979] focus:outline-none focus:ring-1 focus:ring-[#4ADE80]"
                :placeholder="$t('auth.signUp.email')"
              />
              <div
                v-if="showSuggestions && filteredDomains.length > 0"
                class="absolute left-0 mt-1 w-full max-h-[200px] overflow-y-auto bg-white rounded-md shadow-lg z-[99999]"
              >
                <div
                  v-for="domain in filteredDomains"
                  :key="domain"
                  @mousedown="selectDomain(domain)"
                  class="p-2 text-[#000] hover:bg-gray-100 cursor-pointer text-sm"
                >
                  {{ domain }}
                </div>
              </div>
            </div>
            <p
              v-if="errors.email"
              class="flex items-center gap-1 text-[10px] text-[#FF9387] mobile:text-[9px] mt-1 mobile:mt-[0] leading-none"
            >
              {{ Array.isArray(errors.email) ? errors.email[0] : errors.email }}
            </p>
          </div>

          <div class="flex flex-col mb-[10px] mobile:mb-1 w-full">
            <div class="relative">
              <input
                v-model="formData.password"
                :type="showPassword ? 'text' : 'password'"
                id="password"
                required
                autocomplete="new-password"
                class="w-full p-2 pl-4 mobile:py-[6px] text-sm mobile:text-[11px] shadow-in rounded-lg text-[#797979] focus:outline-none focus:ring-1 focus:ring-[#4ADE80]"
                :placeholder="$t('auth.signUp.password')"
              />
              <button
                type="button"
                @click="showPassword = !showPassword"
                class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm mobile:text-[11px] leading-5"
              >
                <EyeOffIcon
                  v-if="!showPassword"
                  class="h-6 w-6 text-[#797979] mobile:h-3 mobile:w-3"
                />
                <EyeIcon
                  v-else
                  class="h-6 w-6 text-[#797979] mobile:h-3 mobile:w-3"
                />
              </button>
            </div>
            <p
              v-if="isSubmitted && errors.password"
              class="text-[10px] text-[#FF9387] mobile:text-[9px] mt-1 mobile:mt-[0] leading-0"
            >
              {{ errors.password[0] }}
            </p>
            <button
              type="button"
              @click="generatePassword"
              class="text-[10px] mobile:text-[10px] text-[#797979] mt-1 flex items-center gap-[5px] font-medium"
            >
              <PasswordGen class="mobile:w-[13px] mobile:h-[10px]" />
              {{ $t("auth.signUp.generatePassword") }}
            </button>
          </div>

          <div class="flex flex-col mb-2 mobile:mb-1 w-full">
            <label
              for="referralCode"
              class="text-sm mobile:text-[11px] text-[#797979] mb-2 mobile:mb-[1px] mobile:leading-[130%]"
              >{{ $t("auth.signUp.referralCode") }}:</label
            >
            <input
              v-model="formData.ref_code"
              type="text"
              id="referralCode"
              class="w-full p-2 pl-4 mobile:py-[6px] text-sm mobile:text-[11px] shadow-in rounded-lg text-[#797979] focus:outline-none focus:ring-1 focus:ring-[#4ADE80]"
              :placeholder="$t('auth.signUp.referralCode')"
            />
          </div>

          <RegisterCheckbox
            :formData="formData"
            :errors="errors"
            @update:formData="handleFormDataUpdate"
          />
        </form>
        <div class="w-[100%] pb-[10px] border-b">
          <button
            type="button"
            @click="handleSubmit"
            class="bg-[#4ADE80] hover:bg-[#4ADE80]/75 text-white font-[500] py-2 px-4 rounded-[5px] w-full mobile:py-1 mobile:px-2 mobile:text-[12px]"
          >
            {{ $t("auth.signUp.register") }}
          </button>
        </div>

        <div class="mt-4 mobile:mt-2">
          <p
            class="text-[13px] font-[500] text-center w-[100%] text-[#797979] mobile:text-[10px] leading-[1] mb-2"
          >
            {{ $t("auth.signUp.oneClickRegister") }}
          </p>
          <div class="flex flex-col gap-[4px]">
            <TelegramEntry
              :showSlotReg="true"
              class="mb-2 !w-[240px] !text-[14px] !justify-start !gap-x-[11px] mobile:!text-[10px] mobile:mb-1"
              @click="openRegisterGoogleTelegram('telegram')"
            />
            <GoogleEntry
              :showSlotReg="true"
              class="mb-2 !w-[240px] !text-[14px] !justify-start !gap-x-[11px] mobile:!text-[10px] mobile:mb-1"
              @click="openRegisterGoogleTelegram('google')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, reactive, onMounted, onUnmounted, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/authStore";
import ModalHeader from "@/components/ModalDeal/ModalBlocks/ModalHeader.vue";
import TelegramEntry from "./TelegramEntry.vue";
import GoogleEntry from "./GoogleEntry.vue";
import EyeIcon from "@/components/icons/EyeIcon.vue";
import EyeOffIcon from "@/components/icons/EyeOffIcon.vue";
import PasswordGen from "@/components/icons/PasswordGen.vue";
import RegisterCheckbox from "./RegisterCheckbox.vue";

const { t } = useI18n();

const emit = defineEmits(["close", "switch-form", "registration-success"]);

const openLoginForm = () => {
  emit("switch-form", "login");
};

const openRegisterGoogleTelegram = (type) => {
  emit("switch-form", "google-telegram", { type });
};

const close = () => emit("close");

const props = defineProps({
  initialEmail: {
    type: String,
    default: "",
  },
});
const { initialEmail } = toRefs(props);

const authStore = useAuthStore();
const isLoading = ref(false);
const showPassword = ref(true);
const isSubmitted = ref(false);
const showSuggestions = ref(false);
const filteredDomains = ref([]);
const errors = ref({});
const generalError = ref("");
const emailInput = ref(props.initialEmail || "");

const emailDomains = [
  "gmail.com",
  "mail.ru",
  "yandex.ru",
  "ya.ru",
  "icloud.com",
];

const checkboxes = [
  "not_us_resident",
  "agree_to_terms",
  "agree_to_marketing",
  "agree_to_asset_use",
  "agree_to_price_regulation",
];

const checkboxFields = [
  "not_us_resident",
  "agree_to_terms",
  "agree_to_marketing",
  "agree_to_asset_use",
  "agree_to_price_regulation",
];

const formData = reactive({
  username: "",
  email: props.initialEmail || "", // Используем props.initialEmail
  password: "",
  ref_code: "",
  not_us_resident: false,
  agree_to_terms: false,
  agree_to_marketing: false,
  agree_to_asset_use: false,
  agree_to_price_regulation: false,
});

const handleEmailInput = (e) => {
  const value = e.target.value;
  emailInput.value = value;
  formData.email = value;
  filterDomains();
};

const validators = {
  username: (value) => {
    const usernameRegex = /^[a-z][a-z0-9_]{5,19}$/;
    if (!value || !usernameRegex.test(value)) {
      return t("auth.signUp.errors.username");
    }
    return null;
  },
  email: (value) => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (!value || !emailRegex.test(value)) {
      return t("auth.signUp.errors.email");
    }
    return null;
  },
  password: (value) => {
    if (!value || value.length < 6 || /\s/.test(value)) {
      return t("auth.signUp.errors.password");
    }
    return null;
  },
};

const errorTranslations = {
  "The username has already been taken.": t("auth.signUp.errors.usernameTaken"),
  "The email has already been taken.": t("auth.signUp.errors.emailTaken"),
};

const validateForm = () => {
  errors.value = {};
  isSubmitted.value = true;

  Object.entries(validators).forEach(([field, validator]) => {
    const error = validator(formData[field]);
    if (error) errors.value[field] = [error];
  });

  checkboxFields.forEach((checkbox) => {
    if (!formData[checkbox]) {
      errors.value[checkbox] = [t("auth.signUp.errors.checkboxRequired")];
    }
  });

  return Object.keys(errors.value).length === 0;
};

const handleFormDataUpdate = (newData) => {
  Object.keys(newData).forEach((key) => {
    if (key.includes("_") && typeof newData[key] === "boolean") {
      formData[key] = newData[key];
    }
  });
};

const filterDomains = () => {
  if (emailInput.value && emailInput.value.length > 0) {
    const [localPart, domain] = emailInput.value.split("@");
    if (domain !== undefined) {
      filteredDomains.value = emailDomains.filter((d) =>
        d.toLowerCase().startsWith(domain.toLowerCase())
      );
    } else {
      filteredDomains.value = emailDomains.map((d) => `${localPart}@${d}`);
    }
    showSuggestions.value = true;
  } else {
    showSuggestions.value = false;
  }
};

const selectDomain = (domain) => {
  emailInput.value = domain.includes("@")
    ? domain
    : emailInput.value.split("@")[0] + "@" + domain;
  formData.email = emailInput.value;
  showSuggestions.value = false;
};

const handleEmailBlur = () => {
  window.setTimeout(() => {
    if (
      !emailInput.value ||
      !emailInput.value.includes("@") ||
      emailInput.value.split("@")[1].length > 0
    ) {
      showSuggestions.value = false;
    }
  }, 200);
};

const handleClickOutside = (event) => {
  if (!event.target.closest("#email")) {
    showSuggestions.value = false;
  }
};

const generatePassword = () => {
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+";
  formData.password = Array.from({ length: 18 }, () =>
    charset.charAt(Math.floor(Math.random() * charset.length))
  ).join("");
};

const handleSubmit = () => {
  if (!validateForm()) return;

  isLoading.value = true;
  generalError.value = "";

  errors.value = {};
  isSubmitted.value = false;

  authStore
    .register(formData)
    .then(() => {
      console.log("Registration successful, passing email:", formData.email);
      emit("registration-success", formData.email);
    })
    .catch((error) => {
      console.log("Full error object:", error);

      if (error?.errors) {
        errors.value = {
          username:
            error.errors.username?.map(
              (err) => errorTranslations[err] || err
            ) || [],
          email:
            error.errors.email?.map((err) => errorTranslations[err] || err) ||
            [],
        };

        console.log("Final translated errors:", errors.value);
      } else {
        generalError.value = "Произошла ошибка при регистрации";
      }
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const updateEmail = (value) => {
  emailInput.value = value;
  formData.email = value;
};

const setPrefillEmail = (email) => {
  emailInput.value = email;
  formData.email = email;
};

onMounted(() => {
  // В момент монтирования тоже используем initialEmail из toRefs
  if (initialEmail.value) {
    emailInput.value = initialEmail.value;
    formData.email = initialEmail.value;
  }
  document.addEventListener("click", handleClickOutside);
});

watch(
  () => props.initialEmail,
  (newValue) => {
    if (newValue) {
      emailInput.value = newValue;
      formData.email = newValue;
    }
  },
  { immediate: true }
);

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

defineExpose({
  close,
  setPrefillEmail,
  updateEmail,
});
</script>
