<template>
  <div
    class="fixed inset-0 bg-gray-900 bg-opacity-50 z-[9999] flex justify-center items-center overflow-y-auto"
  >
    <div
      class="flex flex-col bg-white rounded-lg w-4/5 max-w-[414px] mobile:w-[100%] mobile:h-full mobile:max-w-full mobile:rounded-none absolute shadow-md text-[#797979] mobile:items-center mobile:top-1 tablet:top-1"
    >
      <ModalHeader @close="close" class="mobile:rounded-none w-full" />
      <div
        class="p-6 px-[55px] mobile:px-6 flex flex-col items-start mobile:max-w-[360px]"
      >
        <h2
          class="text-[16px] text-[#000] text-center w-full font-[500] mb-[12px] leading-[100%] text-[#797979]"
        >
          {{ $t("auth.emailVerification.title") }}
        </h2>
        <p class="text-[12px] font-[500] max-[767px]:text-[9px] mb-1">
          {{ $t("auth.emailVerification.openEmail") }}
        </p>

        <p class="text-[10px] font-[500] mb-[3px]">
          {{ $t("auth.emailVerification.codeSentTo") }}
          <span class="text-[#3C92C4]">{{ email }}</span>
        </p>
        <div class="relative w-full">
          <input
            ref="input"
            v-model="verificationCode"
            @input="validateCode"
            class="w-full p-3 pr-24 shadow-in rounded-lg text-sm"
            :placeholder="$t('auth.emailVerification.inputPlaceholder')"
            maxlength="6"
            pattern="[A-Za-z0-9]*"
          />
        </div>
        <button
          @click="resendCode"
          type="button"
          :disabled="!canResend || isResending"
          class="text-[#797979] w-full flex gap-1 justify-end items-center text-sm mt-2 hover:underline disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <ResendIcon />
          <span v-if="isResending">{{
            $t("auth.emailVerification.resending")
          }}</span>
          <span v-else-if="!canResend">{{
            $t("auth.emailVerification.resendIn", { seconds: resendTimeout })
          }}</span>
          <span v-else>{{ $t("auth.emailVerification.resendButton") }}</span>
        </button>
        <p class="text-[10px] font-[500] text-[#FF6663] mt-1" v-if="error">
          {{ error }}
        </p>
        <button
          @click="verifyEmail"
          type="submit"
          class="bg-[#4ADE80] mt-4 mb-[14px] w-full hover:bg-[#4ADE80]/40 text-white font-[500] text-[16px] h-[40px] px-4 rounded-lg"
          :disabled="isVerifying"
        >
          {{
            isVerifying
              ? $t("auth.emailVerification.verifying")
              : $t("auth.emailVerification.verify")
          }}
        </button>
        <button
          @click="goBack"
          class="bg-transparent text-nowrap h-[38px] text-[#ff9387] h-[40px] px-5 rounded border-none cursor-pointer text-base w-full shadow hover:shadow-md mb-[11px]"
        >
          {{ $t("auth.emailVerification.back") }}
        </button>
        <p class="text-[10px] font-[500] text-center px-[45px]">
          {{ $t("auth.emailVerification.noCodeReceived") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useI18n } from "vue-i18n";
import ModalHeader from "../ModalDeal/ModalBlocks/ModalHeader.vue";
import ResendIcon from "@/components/icons/ResendIcon.vue";

const { t } = useI18n();

const props = defineProps({
  email: {
    type: String,
    required: true,
  },
});

console.log("ConfirmEmail props:", props);

watch(
  () => props.email,
  (newValue) => {
    console.log("Email changed:", newValue);
  },
  { immediate: true }
);

const authStore = useAuthStore();
const verificationCode = ref("");
const isVerifying = ref(false);
const isPasting = ref(false);
const error = ref("");
const input = ref(null);
const isResending = ref(false);
const resendTimeout = ref(60);
const canResend = ref(true);

const emit = defineEmits(["close", "verified", "back"]);

const startResendTimer = () => {
  canResend.value = false;
  const timer = setInterval(() => {
    resendTimeout.value--;
    if (resendTimeout.value <= 0) {
      clearInterval(timer);
      canResend.value = true;
      resendTimeout.value = 60;
    }
  }, 1000);
};

const validateCode = () => {
  verificationCode.value = verificationCode.value.replace(/[^A-Za-z0-9]/g, "");

  verificationCode.value = verificationCode.value.toUpperCase();

  if (verificationCode.value) {
    error.value = "";
  }
};

const verifyEmail = () => {
  const trimmedCode = verificationCode.value.trim();

  if (!props.email || !trimmedCode) {
    error.value = t("auth.emailVerification.errors.emptyCode");
    return;
  }

  console.log("Attempting to verify with:", {
    email: props.email,
    code: trimmedCode,
  });

  isVerifying.value = true;
  error.value = "";

  authStore
    .verifyEmail(props.email, trimmedCode)
    .then((response) => {
      console.log("Verification response:", response);

      if (
        response?.message === "User's email successfully verified." ||
        response.success ||
        response.status === 200 ||
        response.data?.success
      ) {
        emit("verified");
      } else {
        error.value =
          response.message ||
          t("auth.emailVerification.errors.verificationFailed");
      }
    })
    .catch((err) => {
      console.error("Error verifying email:", err);

      error.value =
        err.response?.data?.message ||
        err.message ||
        t("auth.emailVerification.errors.verificationFailed");

      console.log("Full error object:", {
        response: err.response,
        data: err.response?.data,
        status: err.response?.status,
        message: err.message,
      });
    })
    .finally(() => {
      isVerifying.value = false;
    });
};

const resendCode = () => {
  if (!canResend.value || isResending.value) return;

  isResending.value = true;
  error.value = "";

  authStore
    .resendVerificationCode(props.email)
    .then(() => {
      startResendTimer();
    })
    .catch((err) => {
      console.error("Error resending code:", err);
      error.value =
        err.response?.data?.message ||
        t("auth.emailVerification.errors.resendFailed");
    })
    .finally(() => {
      isResending.value = false;
    });
};

onMounted(() => {
  console.log("ConfirmEmail mounted with email:", props.email);

  // Если email пустой, но есть в authStore, используем его оттуда
  if (!props.email && authFormsStore.formData.email) {
    console.log("Using email from store:", authFormsStore.formData.email);
    // В компоненте, использующем ConfirmEmail, добавить:
    // email = authFormsStore.formData.email;
  }
});

const close = () => emit("close");
const goBack = () => emit("back");

defineExpose({ close });
</script>
