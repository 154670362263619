<template>
  <div
    class="flex items-center gap-[5px]"
    :class="isCollapsed ? 'justify-center flex-col' : 'justify-start'"
  >
    <SpotIcon
      :class="isCollapsed ? 'w-[27px] h-[38px]' : 'w-[20px] h-[20px] mr-1.5'"
    />
    <span :class="isCollapsed ? 'text-[12px]' : 'text-[16px]'" class="relative">
      {{ $t("articleroute.spot") }}
    </span>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import SpotIcon from "./icons/SpotIcon.vue";

defineProps({
  isCollapsed: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
</script>
