<template>
  <div>
    <header
      class="py-[12px] desktop:py-[9px] mobile:py-[7px] px-4 text-white bg-[#fc7a78] mobile:relative"
      :class="{ loading: isLoading }"
    >
      <div
        class="container mx-auto !max-w-[100%] flex justify-between items-center"
      >
        <div class="flex items-center">
          <!-- Кнопка для скрытия/показа меню -->
          <button @click="menuStore.toggleHidden" class="mobile:hidden mr-4">
            <BurgerMenuPk />
          </button>
          <!-- Кнопка переключения меню для мобильных устройств -->
          <button
            @click="toggleMenu"
            class="hidden mr-4 mobile:block tablet:block"
          >
            <BurgerMenu />
          </button>
        </div>

        <div
          @click="goToHome"
          class="flex items-center cursor-pointer gap-[6px] absolute left-1/2 transform -translate-x-1/2"
        >
          <FinalLogo
            class="h-[40px] w-[54px] desktop:w-[39px] mobile:w-[40px] mobile:h-[30px]"
          />
        </div>

        <div
          class="flex items-center justify-center mobile:justify-end gap-[25px] mobile:gap-[15px]"
        >
          <EntryBlock v-if="!user" />
          <template v-else>
            <div
              @click="toggleMobileNotifications"
              class="flex flex-col items-center cursor-pointer justify-center"
            >
              <Notification />
              <span
                class="text-[9px] mobile:hidden tablet:hidden custom-shadow"
                >{{ $t("buttonheader.notification") }}</span
              >
            </div>
            <ProfileMenu @go-to-profile="goToProfile" @logout="handleLogout" />
          </template>
          <Language />
        </div>
      </div>
      <NotificationPopup v-if="user" />

      <Teleport to="body">
        <NotificationListMobile
          v-if="user && isMobileNotificationsOpen"
          :notifications="filteredNotifications"
          @close="closeMobileNotifications"
        />
      </Teleport>
      <NotificationList v-if="user && !isMobile" ref="notificationList" />
    </header>

    <Teleport to="body">
      <Transition name="fade">
        <div
          v-if="isMenuOpen"
          class="fixed inset-0 bg-gray-900 bg-opacity-50 z-[10000]"
          @click="closeMenu"
        >
          <div
            class="fixed top-0 left-0 h-full bg-white overflow-y-auto custom-scrollbar-none mobile:w-[306px] mobilemini:w-[280px] tablet:w-[306px] flex flex-col"
            @click.stop
          >
            <ArticleComponent
              @navigation-event="closeMenu"
              @update-user="handleUserUpdate"
              :hide-balance="hideBalance"
              :formatted-total-balance="calculateTotalBalance"
              @toggle-balance="toggleBalance"
            />
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useNavigationStore } from "../stores/marketStore";
import { useUserStore } from "@/stores/userStore";
import { useNotificationStore } from "@/stores/notificationStore";
import { useMenuStore } from "../stores/menuStore"; // Импортируем стор меню
import EntryBlock from "../components/auth/EntryBlock.vue";
import BurgerMenu from "../components/icons/BurgerMenu.vue";
import BurgerMenuPk from "../components/icons/BurgerMenuPk.vue";
import ProfileMenu from "../blocks/ProfileMenu.vue";
import Language from "../components/Language.vue";
import Notification from "@/components/icons/Notification.vue";
import NotificationList from "../blocks/NotificationList.vue";
import NotificationPopup from "../blocks/NotificationPopup.vue";
import NotificationListMobile from "../blocks/NotificationListMobile.vue";
import FinalLogo from "@/components/icons/FinalLogo.vue";
import ArticleComponent from "../blocks/Article.vue";
import { useI18n } from "vue-i18n";
import { shouldShowNotification } from "@/helpers/notificationHelper";

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const router = useRouter();
const navigationStore = useNavigationStore();
const userStore = useUserStore();
const notificationStore = useNotificationStore();
const menuStore = useMenuStore(); // Используем стор меню

const { user } = storeToRefs(userStore);
const isMobileNotificationsOpen = ref(false);
const isMobile = ref(window.innerWidth < 768);
const isMenuOpen = ref(false);
const notificationList = ref(null);
const hideBalance = ref(false);

const calculateTotalBalance = computed(() => {
  if (!user.value?.balance) return "0";
  const total = Object.values(user.value.balance).reduce(
    (sum, value) => sum + (Number(value) || 0),
    0
  );
  return total.toFixed(0);
});

watch(
  () => router.currentRoute.value,
  () => {
    closeMenu();
  }
);

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;

  if (isMenuOpen.value) {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100%";
    document.body.style.position = "fixed";
    document.body.style.width = "100%";
  } else {
    document.body.style.overflow = "";
    document.body.style.height = "";
    document.body.style.position = "";
    document.body.style.width = "";
  }
}

function closeMenu() {
  isMenuOpen.value = false;

  document.body.style.overflow = "";
  document.body.style.height = "";
  document.body.style.position = "";
  document.body.style.width = "";
}

function toggleNotificationList() {
  if (notificationList.value) {
    notificationList.value.toggleVisibility();
  }
}

const filteredNotifications = computed(() => {
  const userId = user.value?.id;
  return notificationStore.notifications
    .filter((notification) => shouldShowNotification(notification, userId))
    .sort((a, b) => {
      if (a.isRead !== b.isRead) {
        return a.isRead ? 1 : -1;
      }
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
});

function toggleMobileNotifications() {
  if (isMobile.value) {
    isMobileNotificationsOpen.value = !isMobileNotificationsOpen.value;
    if (isMobileNotificationsOpen.value) {
      notificationStore.fetchUnreadNotifications();
    }
  } else {
    toggleNotificationList();
  }
}

function closeMobileNotifications() {
  isMobileNotificationsOpen.value = false;
}

function toggleBalance() {
  hideBalance.value = !hideBalance.value;
}

function handleLogout() {
  userStore
    .logout()
    .then(() => {
      navigationStore.showMain();
      closeMenu();
    })
    .catch((error) => {
      console.error("Logout error:", error);
    });
}

function handleUserUpdate() {
  return userStore.fetchUser().catch((error) => {
    console.error("User update error:", error);
  });
}

function goToHome() {
  router.push({ name: "landing" });
  closeMenu();
}

function goToProfile() {
  navigationStore.showUserProfile();
  closeMenu();
}

function handleResize() {
  isMobile.value = window.innerWidth < 640;
}

onMounted(() => {
  // Инициализация состояния меню из стора
  menuStore.initialize();

  document.addEventListener("keydown", handleEscKey);
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  document.removeEventListener("keydown", handleEscKey);
  window.removeEventListener("resize", handleResize);
});

function handleEscKey(event) {
  if (event.key === "Escape") {
    if (isMenuOpen.value) {
      closeMenu();
    }
    if (isMobileNotificationsOpen.value) {
      closeMobileNotifications();
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

header.loading {
  cursor: wait;
  pointer-events: none;
  opacity: 0.7;
}
</style>
