<template>
<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.58855 13.3619C6.65977 13.3405 6.73102 13.3334 6.80934 13.3334C7.07285 13.3334 7.31504 13.4616 7.4575 13.661C7.55723 13.2907 7.8991 13.02 8.29797 13.02C8.37633 13.02 8.44754 13.0271 8.51875 13.0485C8.57574 12.6283 8.93898 12.3077 9.36633 12.3077C9.67258 12.3077 9.93613 12.4644 10.0928 12.7066C10.2638 12.5428 10.4988 12.4431 10.7481 12.4431C11.0543 12.4431 11.325 12.5856 11.4959 12.8063C11.6526 12.4075 12.0373 12.1226 12.4931 12.1226C13.0486 12.1226 13.5045 12.5428 13.5614 13.0841C13.6113 13.077 13.6612 13.0699 13.711 13.0699C13.9674 13.0699 14.1954 13.1981 14.3307 13.3904C14.4802 13.2764 14.6725 13.2052 14.872 13.2052C15.1996 13.2052 15.4845 13.3832 15.6412 13.6396C15.0073 14.0385 14.3164 14.3519 13.5828 14.5656C13.3264 14.5228 13.1127 14.3519 13.013 14.124C12.8563 14.2094 12.6782 14.2593 12.493 14.2593C12.1227 14.2593 11.8021 14.0741 11.6098 13.7963C11.4603 14.1239 11.1255 14.3447 10.748 14.3447C10.3848 14.3447 10.0643 14.1382 9.90043 13.8319C9.75086 13.9459 9.56566 14.0171 9.36625 14.0171C9.29504 14.0171 9.23094 14.01 9.15969 13.9958C9.13121 14.2023 9.03859 14.3804 8.89617 14.5157C8.0557 14.2522 7.27223 13.8604 6.57422 13.3547L6.58855 13.3619ZM12.194 14.8434C11.9091 14.8718 11.6242 14.8861 11.3321 14.8861H11.325C11.3891 14.7081 11.5672 14.5727 11.7666 14.5727C11.9518 14.5727 12.1156 14.6795 12.194 14.8362V14.8434ZM10.3279 14.8291C10.1783 14.8077 10.0287 14.7864 9.87914 14.7579C9.88625 14.6368 9.98598 14.5442 10.1071 14.5442C10.2353 14.5442 10.335 14.644 10.335 14.7722C10.335 14.7935 10.335 14.8078 10.3279 14.822V14.8291H10.3279Z" fill="#FAB718" />
  <path d="M15.8551 1.33197C7.45766 0.16388 9.28102 8.14822 13.3622 5.9331C15.2354 4.91458 14.7938 2.00861 15.8551 1.33197Z" fill="#5FDC68" />
  <path d="M5.24219 5.45587C11.5314 3.06981 11.396 9.01712 8.14105 8.1197C6.53852 7.67809 6.1966 5.69095 5.24219 5.45587Z" fill="#5FDC68" />
  <path d="M10.8042 9.67955L10.6689 9.68666C10.1845 6.35334 7.86262 6.09693 7.85547 6.08978C9.52926 6.34623 10.676 7.57842 10.8042 9.67955Z" fill="#FFE356" />
  <path d="M15.5345 7.57126C10.3636 5.66244 10.5202 10.5342 13.177 9.77212C14.4875 9.39462 14.751 7.77072 15.5345 7.57126Z" fill="#5FDC68" />
  <path d="M10.668 11.3391L10.8033 11.3676C11.7434 7.97727 14.1865 8.00575 14.1936 8.00575C12.4272 8.05559 11.0739 9.18094 10.668 11.3462V11.3391Z" fill="#FFE356" />
  <path d="M14.3582 2.20093C9.84253 3.49011 11.0605 9.7223 11.1459 13.0628L10.5975 13.2337C10.4551 10.114 9.65023 2.99151 14.3582 2.20093Z" fill="#FFE356" />
  <path d="M7.02344 13.6539C7.25137 13.6752 7.45078 13.7963 7.58613 13.9673C7.68586 13.6111 8.01348 13.3476 8.40523 13.3476C8.47644 13.3476 8.5548 13.3547 8.61891 13.3761C8.6759 12.9701 9.02488 12.6638 9.44512 12.6638C9.73715 12.6638 10.0007 12.8134 10.1431 13.0484C10.307 12.8918 10.5348 12.792 10.777 12.792C11.0691 12.792 11.3326 12.9273 11.5035 13.141C11.6531 12.7493 12.0306 12.4786 12.4722 12.4786C13.0064 12.4786 13.4551 12.8846 13.505 13.4117C13.5548 13.4046 13.5975 13.3974 13.6474 13.3974C13.8967 13.3974 14.1175 13.5185 14.2457 13.7108C14.3953 13.5969 14.5733 13.5328 14.7728 13.5328C15.0149 13.5328 15.2357 13.6325 15.3996 13.7963C14.203 14.4943 12.807 14.8932 11.3255 14.8932C9.74429 14.8932 8.26996 14.4445 7.02352 13.661V13.6539H7.02344Z" fill="#FFF72D" />
  <path d="M8.83859 12.4217C10.199 12.8134 12.7132 13.2336 12.7132 13.2336C12.7132 13.2336 12.5708 14.1382 11.8087 14.3092C11.0466 14.4801 9.01664 14.0812 9.01664 14.0812C9.01664 14.0812 8.64629 14.4445 9.30152 14.5656C9.9568 14.6867 13.1548 14.6154 13.1548 14.6154C13.1548 14.6154 16.4454 13.0556 17.0081 12.849C17.5708 12.6425 17.9127 13.1624 17.9127 13.1624C17.9127 13.1624 14.1306 16.161 13.7745 16.4174C13.4184 16.6809 7.53516 17.1867 7.53516 17.1867L5.79016 17.3647L3.9098 18.8604C2.94113 18.1126 2.10781 17.1938 1.4668 16.1397L4.15199 14.181C5.14203 12.5215 7.4782 12.0229 8.83859 12.4146V12.4217Z" fill="#FED298" />
  <path d="M17.4011 13.5684C16.3541 14.3946 14.0535 16.218 13.7757 16.4174C13.4196 16.6809 7.53641 17.1866 7.53641 17.1866L5.79141 17.3647L3.91105 18.8604C3.84695 18.8106 3.78285 18.7607 3.71875 18.7108C4.76574 17.8989 5.92672 16.9658 5.83414 17.0157C5.74156 17.0655 13.0564 16.4957 13.6333 16.1966C14.2173 15.8975 17.0093 13.7536 17.1304 13.5969C17.2515 13.4474 17.4011 13.5684 17.4011 13.5684Z" fill="#F0BA7D" />
  <path d="M11.6943 13.1553L11.623 13.4901L12.4065 13.5399C12.4635 13.4758 12.5063 13.3975 12.4777 13.2693L11.6943 13.1624V13.1553Z" fill="white" />
  <path d="M17.3066 13.4117L17.3992 13.5115L17.8195 13.2123C17.8479 13.1553 17.7197 13.134 17.7197 13.134L17.3066 13.4117Z" fill="white" />
</svg>
</template>
