<template>
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="21" height="18" fill="#FFC3C3" />
    <g filter="url(#filter0_d_0_1)">
      <rect x="-568" y="-776" width="1440" height="1302" fill="#F3F4F6" />
      <rect x="-568" y="-776" width="1440" height="1302" stroke="#374151" />
    </g>
    <g filter="url(#filter1_d_0_1)">
      <rect x="-43" y="-273" width="306" height="778" rx="10" fill="white" />
      <path
        d="M20.9999 3.88731H0L1.2676 0H19.7323L20.9999 3.88731Z"
        fill="#EF3124"
      />
      <path
        d="M19.7324 0H18.4648L19.7324 3.88731H21L19.7324 0Z"
        fill="#EF3124"
      />
      <path
        d="M6.00121 3.88731L6.63501 0H3.86741L3.00122 3.88731H6.00121Z"
        fill="#E1E1E3"
      />
      <path d="M15 3.88731L14.3662 0H17.1761L18 3.88731H15Z" fill="#E1E1E3" />
      <path
        d="M9.00098 3.88731L9.31788 0H11.6841L12.001 3.88731H9.00098Z"
        fill="#E1E1E3"
      />
      <path
        d="M6.00121 3.88748V5.59874C6.00121 5.99657 5.84317 6.37809 5.56187 6.6594C5.28057 6.9407 4.89904 7.09874 4.50121 7.09874C4.10339 7.09874 3.72186 6.9407 3.44056 6.6594C3.15926 6.37809 3.00122 5.99657 3.00122 5.59874V3.88748H6.00121Z"
        fill="white"
      />
      <path
        d="M2.99999 3.88748V5.59874C2.99999 5.79572 2.96119 5.99078 2.88581 6.17277C2.81043 6.35475 2.69994 6.52011 2.56065 6.6594C2.42136 6.79869 2.256 6.90917 2.07402 6.98456C1.89203 7.05994 1.69698 7.09874 1.49999 7.09874C1.10217 7.09874 0.720641 6.9407 0.439338 6.6594C0.158035 6.37809 0 5.99657 0 5.59874V3.88748H2.99999ZM8.99996 3.88748V5.59874C8.99996 5.99657 8.84193 6.37809 8.56063 6.6594C8.27932 6.9407 7.89779 7.09874 7.49997 7.09874C7.10215 7.09874 6.72062 6.9407 6.43931 6.6594C6.15801 6.37809 5.99998 5.99657 5.99998 5.59874V3.88748H8.99996Z"
        fill="#EF3124"
      />
      <path
        d="M12.001 3.8877V5.59896C12.001 5.79594 11.9622 5.99099 11.8868 6.17298C11.8114 6.35497 11.7009 6.52032 11.5616 6.65961C11.4223 6.7989 11.257 6.90939 11.075 6.98477C10.893 7.06015 10.698 7.09895 10.501 7.09895C10.1031 7.09895 9.72162 6.94092 9.44031 6.65961C9.15901 6.37831 9.00098 5.99678 9.00098 5.59896V3.8877H12.001Z"
        fill="white"
      />
      <path
        d="M14.999 3.88748V5.59874C14.999 5.99657 14.841 6.37809 14.5597 6.6594C14.2784 6.9407 13.8968 7.09874 13.499 7.09874C13.1012 7.09874 12.7197 6.9407 12.4384 6.6594C12.1571 6.37809 11.999 5.99657 11.999 5.59874V3.88748H14.999Z"
        fill="#EF3124"
      />
      <path
        d="M17.999 3.8877V5.59896C17.999 5.99678 17.841 6.37831 17.5597 6.65961C17.2784 6.94092 16.8968 7.09895 16.499 7.09895C16.1012 7.09895 15.7197 6.94092 15.4384 6.65961C15.1571 6.37831 14.999 5.99678 14.999 5.59896V3.8877H17.999Z"
        fill="white"
      />
      <path
        d="M17.998 3.8877V5.59896C17.998 5.99678 18.1561 6.37831 18.4374 6.65961C18.7187 6.94092 19.1002 7.09895 19.498 7.09895C19.8959 7.09895 20.2774 6.94092 20.5587 6.65961C20.84 6.37831 20.998 5.99678 20.998 5.59896V3.8877H17.998Z"
        fill="#EF3124"
      />
      <path
        d="M19.7314 4.8877V6.59896C19.7314 6.88452 19.6499 7.16415 19.4965 7.40499C19.3431 7.64582 19.1241 7.83785 18.8652 7.9585C19.0637 8.05122 19.28 8.09917 19.499 8.09895C19.696 8.09895 19.8911 8.06016 20.0731 7.98477C20.255 7.90939 20.4204 7.7989 20.5597 7.65962C20.699 7.52033 20.8095 7.35497 20.8848 7.17298C20.9602 6.99099 20.999 6.79594 20.999 6.59896V4.8877L19.7314 4.8877Z"
        fill="#EF3124"
      />
      <mask id="path-15-inside-1_0_1" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.46429 16.2536V7.70703C2.85049 8.36499 4.47097 8.36401 5.85717 7.70703C6.54356 8.0326 7.29391 8.20102 8.0536 8.20001C8.83846 8.20001 9.58427 8.02234 10.25 7.70605C10.9364 8.03196 11.6867 8.20071 12.4465 8.20001C13.2313 8.20001 13.9762 8.02234 14.6429 7.70605C16.0291 8.36401 17.6496 8.36499 19.0358 7.70703V16.2536H19.7679C19.9621 16.2536 20.1483 16.3308 20.2856 16.4681C20.4229 16.6054 20.5001 16.7916 20.5001 16.9858C20.5001 17.1799 20.4229 17.3662 20.2856 17.5035C20.1483 17.6408 19.9621 17.7179 19.7679 17.7179H0.732146C0.537969 17.7179 0.351744 17.6408 0.214441 17.5035C0.0771366 17.3662 0 17.1799 0 16.9858C0 16.7916 0.0771366 16.6054 0.214441 16.4681C0.351744 16.3308 0.537969 16.2536 0.732146 16.2536H1.46429ZM4.39287 10.3965C4.39287 10.2023 4.47001 10.016 4.60731 9.87874C4.74462 9.74144 4.93084 9.6643 5.12502 9.6643H8.0536C8.24778 9.6643 8.434 9.74144 8.57131 9.87874C8.70861 10.016 8.78575 10.2023 8.78575 10.3965V13.325C8.78575 13.5192 8.70861 13.7054 8.57131 13.8427C8.434 13.98 8.24778 14.0572 8.0536 14.0572H5.12502C4.93084 14.0572 4.74462 13.98 4.60731 13.8427C4.47001 13.7054 4.39287 13.5192 4.39287 13.325V10.3965ZM12.4465 9.6643C12.2523 9.6643 12.0661 9.74144 11.9288 9.87874C11.7915 10.016 11.7143 10.2023 11.7143 10.3965V15.5215C11.7143 15.9256 12.0423 16.2536 12.4465 16.2536H15.3751C15.5692 16.2536 15.7555 16.1765 15.8928 16.0392C16.0301 15.9019 16.1072 15.7157 16.1072 15.5215V10.3965C16.1072 10.2023 16.0301 10.016 15.8928 9.87874C15.7555 9.74144 15.5692 9.6643 15.3751 9.6643H12.4465Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.46429 16.2536V7.70703C2.85049 8.36499 4.47097 8.36401 5.85717 7.70703C6.54356 8.0326 7.29391 8.20102 8.0536 8.20001C8.83846 8.20001 9.58427 8.02234 10.25 7.70605C10.9364 8.03196 11.6867 8.20071 12.4465 8.20001C13.2313 8.20001 13.9762 8.02234 14.6429 7.70605C16.0291 8.36401 17.6496 8.36499 19.0358 7.70703V16.2536H19.7679C19.9621 16.2536 20.1483 16.3308 20.2856 16.4681C20.4229 16.6054 20.5001 16.7916 20.5001 16.9858C20.5001 17.1799 20.4229 17.3662 20.2856 17.5035C20.1483 17.6408 19.9621 17.7179 19.7679 17.7179H0.732146C0.537969 17.7179 0.351744 17.6408 0.214441 17.5035C0.0771366 17.3662 0 17.1799 0 16.9858C0 16.7916 0.0771366 16.6054 0.214441 16.4681C0.351744 16.3308 0.537969 16.2536 0.732146 16.2536H1.46429ZM4.39287 10.3965C4.39287 10.2023 4.47001 10.016 4.60731 9.87874C4.74462 9.74144 4.93084 9.6643 5.12502 9.6643H8.0536C8.24778 9.6643 8.434 9.74144 8.57131 9.87874C8.70861 10.016 8.78575 10.2023 8.78575 10.3965V13.325C8.78575 13.5192 8.70861 13.7054 8.57131 13.8427C8.434 13.98 8.24778 14.0572 8.0536 14.0572H5.12502C4.93084 14.0572 4.74462 13.98 4.60731 13.8427C4.47001 13.7054 4.39287 13.5192 4.39287 13.325V10.3965ZM12.4465 9.6643C12.2523 9.6643 12.0661 9.74144 11.9288 9.87874C11.7915 10.016 11.7143 10.2023 11.7143 10.3965V15.5215C11.7143 15.9256 12.0423 16.2536 12.4465 16.2536H15.3751C15.5692 16.2536 15.7555 16.1765 15.8928 16.0392C16.0301 15.9019 16.1072 15.7157 16.1072 15.5215V10.3965C16.1072 10.2023 16.0301 10.016 15.8928 9.87874C15.7555 9.74144 15.5692 9.6643 15.3751 9.6643H12.4465Z"
        fill="#FECACA"
        fill-opacity="0.25"
        stroke="#CBCBCB"
        mask="url(#path-15-inside-1_0_1)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_0_1"
        x="-572.5"
        y="-776.5"
        width="1449"
        height="1311"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_0_1"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_0_1"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_0_1"
        x="-47"
        y="-277"
        width="315"
        height="786"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_0_1"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_0_1"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
