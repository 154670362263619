<template>
  <LoadingBar :is-loading="isLoading" />
  <div class="flex flex-col min-h-screen">
    <Header
      v-if="!isSocialNetwork && !isSocialChannel"
      :is-loading="isLoading"
    />

    <template v-if="isGoogleCallback">
      <GoogleAuthCallback @auth-complete="handleAuthComplete" />
    </template>

    <template v-else-if="isSocialNetwork">
      <SocialNetwork v-if="shouldRenderComponent" />
    </template>

    <template v-else-if="isSocialChannel">
      <SocialChannel v-if="shouldRenderComponent" />
    </template>

    <template v-else>
      <component :is="currentComponent" v-if="shouldRenderComponent" />
    </template>

    <AuthForms
      v-if="authFormsStore.isVisible"
      ref="authForms"
      @close="handleAuthFormClose"
      @login-success="handleLoginSuccess"
      :initial-form="authFormsStore.currentForm"
      :initial-email="authFormsStore.initialEmail"
    />
  </div>

  <FixedLine class="mobile:hidden"/>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineAsyncComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "./stores/userStore";
import { useAuthStore } from "./stores/authStore";
import { useAuthFormsStore } from "./stores/authFormsStore";

import Header from "./blocks/Header.vue";
import AuthForms from "./components/auth/AuthForms.vue";
import GoogleAuthCallback from "./components/auth/GoogleAuthCallback.vue";
import LoadingBar from "./components/LoadingBar.vue";
import SocialNetwork from "./blocks/SocialNetwork.vue";
import FixedLine from "./blocks/FixedLine.vue";
import SocialChannel from "./blocks/SocialChannel.vue";

const Page = defineAsyncComponent(() => import("./pages/index.vue"));
const Rules = defineAsyncComponent(() => import("./pages/rules.vue"));
const LandingPage = defineAsyncComponent(() =>
  import("./pages/LandingPage.vue")
);
const InvestorPage = defineAsyncComponent(() => import("./pages/Investor.vue"));

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const authStore = useAuthStore();
const authFormsStore = useAuthFormsStore();

const authForms = ref(null);
const isProcessingAuth = ref(false);
const isLoading = ref(false);

// Добавляем определение Telegram WebApp
const isTelegramWebApp = computed(() => !!window.Telegram?.WebApp);
const isGamePage = computed(() => route.name === "game");
const isAuthenticated = computed(() => authStore.isAuthenticated);
const isGoogleCallback = computed(() => Boolean(route.query.code));
const isMainRoute = computed(() => route.name === "main");
const isLandingPage = computed(() => route.name === "landing");
const isInvestorPage = computed(() => route.name === "investor");
const isSocialNetwork = computed(() => route.name === "social-network");
const isSocialChannel = computed(() => route.name === "social-channel");
const isRulesRoute = computed(() =>
  ["userAgreement", "amlKycPolicy", "cookies", "privacyPolicy"].includes(
    route.name
  )
);

const currentComponent = computed(() => {
  if (isLandingPage.value) return LandingPage;
  if (isRulesRoute.value) return Rules;
  if (isInvestorPage.value) return InvestorPage;
  return Page;
});

const canAccessRoute = computed(() => {
  if (
    isMainRoute.value ||
    isInvestorPage.value ||
    (isGamePage.value && isTelegramWebApp.value)
  )
    return true;
  const requiresAuth = route.meta.requiresAuth;
  return !requiresAuth || isAuthenticated.value;
});

const shouldRenderComponent = computed(
  () =>
    !isGoogleCallback.value &&
    (isMainRoute.value ||
      isLandingPage.value ||
      isInvestorPage.value ||
      isSocialNetwork.value ||
      isSocialChannel.value ||
      isGamePage.value ||
      canAccessRoute.value)
);

// Обработчик автоматической авторизации через Telegram
const handleTelegramAuth = async () => {
  if (isTelegramWebApp.value && !authStore.isAuthenticated) {
    try {
      isLoading.value = true;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await authStore.authenticateWithTelegram();

      // Если успешно, можно дополнительно обработать
      window.Telegram.WebApp.MainButton.setText("Игра").show();
    } catch (error) {
      console.error("Ошибка авторизации Telegram:", error);

      // Показать пользователю сообщение об ошибке
      const tg = window.Telegram?.WebApp;
      if (tg) {
        tg.showAlert("Не удалось авторизоваться. Попробуйте позже.");
      }
    } finally {
      isLoading.value = false;
    }
  }
};

function handleLoginSuccess(user) {
  console.group("handleLoginSuccess");
  console.log("User:", user);
  console.log("Is Telegram Web App:", isTelegramWebApp.value);
  console.log("Current Route:", route.name);

  return userStore
    .updateUser(user)
    .then(() => {
      console.log("User updated successfully");
      return userStore.setAuthenticated(true);
    })
    .then(() => {
      console.log("User authenticated");

      if (isTelegramWebApp.value && isGamePage.value) {
        console.log("Telegram Web App on Game Page, skipping route change");
        return;
      }

      const intendedRoute = route.query.intendedRoute;
      if (intendedRoute) {
        console.log("Navigating to intended route:", intendedRoute);
        return router.push(intendedRoute);
      }
    })
    .then(() => {
      console.log("Closing auth form");
      return handleAuthFormClose();
    })
    .catch((error) => {
      console.error("Ошибка обработки успешного входа:", error);
    })
    .finally(() => {
      console.groupEnd();
    });
}

function handleAuthFormClose() {
  console.log("Closing auth form");
  return authFormsStore.close().catch((error) => {
    console.error("Ошибка закрытия формы авторизации:", error);
  });
}

function handleAuthComplete(success) {
  isProcessingAuth.value = false;
  if (success) {
    router.push("/p2p");
  } else {
    authFormsStore
      .updateFormState({
        currentForm: "login",
        isVisible: true,
      })
      .then(() => {
        router.push({
          name: "main",
          query: { error: "Ошибка аутентификации" },
        });
      });
  }
}

watch(
  () => route.query.code,
  (newCode) => {
    isProcessingAuth.value = Boolean(newCode);
  }
);

onMounted(() => {
  // Загрузка скриптов Telegram
  const webAppScript = document.createElement("script");
  webAppScript.async = true;
  webAppScript.src = "https://telegram.org/js/telegram-web-app.js";

  webAppScript.onload = () => {
    if (isGamePage.value) {
      // Добавляем задержку после загрузки скрипта
      setTimeout(() => {
        handleTelegramAuth();
      }, 1000);
    }
  };

  document.head.appendChild(webAppScript);

  // Обработка параметров формы
  const { form, email } = route.query;
  if (form && !isTelegramWebApp.value) {
    authFormsStore.updateFormState({
      currentForm: form,
      initialEmail: email || "",
      isVisible: true,
    });
  }
});

watch(
  () => isAuthenticated.value,
  (isAuthenticated) => {
    console.group("Authentication Watch");
    console.log("Authenticated:", isAuthenticated);
    console.log("Route query code:", route.query.code);
    console.log("Is Telegram Web App:", isTelegramWebApp.value);

    if (isAuthenticated) {
      if (route.query.code && !isTelegramWebApp.value) {
        console.log("Navigating to /p2p");
        router.push("/p2p");
      }
      if (isGamePage.value) {
        console.log("Closing auth form on game page");
        handleAuthFormClose();
      }
    }
    console.groupEnd();
  }
);
</script>
