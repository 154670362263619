<template>
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_3025_14455)">
      <ellipse
        cx="25.1538"
        cy="21.1633"
        rx="21.1538"
        ry="21.1539"
        fill="#FFEBEB"
      />
      <path
        d="M46.0577 21.1633C46.0577 32.7083 36.6987 42.0673 25.1538 42.0673C13.609 42.0673 4.25 32.7083 4.25 21.1633C4.25 9.61841 13.609 0.259399 25.1538 0.259399C36.6987 0.259399 46.0577 9.61841 46.0577 21.1633Z"
        stroke="#FFA563"
        stroke-width="0.5"
      />
    </g>
    <path
      d="M35.7051 17.4562H14.7793V30.1937H35.7051V17.4562Z"
      fill="#99A1CB"
    />
    <path
      d="M35.7051 22.9152H14.7793V25.6446H35.7051V22.9152Z"
      fill="#C7A659"
    />
    <path
      d="M27.5178 17.4564H22.9688V30.1939H27.5178V17.4564Z"
      fill="#FFD572"
    />
    <path
      d="M36.6166 14.2721H13.8711V17.9114H36.6166V14.2721Z"
      fill="#4D5166"
    />
    <path
      d="M24.7384 21.551L22.9188 20.1863L22.009 21.551C20.6964 19.7135 20.9047 17.1963 22.5015 15.5995L22.9188 15.1823H25.6482L25.231 15.5995C23.6342 17.1963 23.4259 19.7135 24.7384 21.551Z"
      fill="#BF4C53"
    />
    <path
      d="M25.7458 21.551L27.5654 20.1863L28.4752 21.551C29.7877 19.7135 29.5795 17.1963 27.9827 15.5995L27.5654 15.1823H24.8359L25.2532 15.5995C26.85 17.1963 27.0583 19.7135 25.7458 21.551Z"
      fill="#BF4C53"
    />
    <path
      d="M29.9411 16.3255L26.1523 15.6367V11.9974L29.9411 11.3085C30.5738 11.1935 31.1563 11.6796 31.1563 12.3228V15.3113C31.1563 15.9544 30.5738 16.4406 29.9411 16.3255Z"
      fill="#E65C64"
    />
    <path
      d="M20.5434 16.3256L24.3321 15.6367V11.9974L20.5434 11.3086C19.9106 11.1935 19.3281 11.6797 19.3281 12.3228V15.3113C19.3281 15.9545 19.9106 16.4406 20.5434 16.3256Z"
      fill="#E65C64"
    />
    <path
      d="M25.9994 16.0916H24.4879C24.1516 16.0916 23.8789 15.819 23.8789 15.4826V12.1515C23.8789 11.8152 24.1516 11.5425 24.4879 11.5425H25.9994C26.3357 11.5425 26.6084 11.8152 26.6084 12.1515V15.4826C26.6084 15.819 26.3357 16.0916 25.9994 16.0916Z"
      fill="#592427"
    />
    <defs>
      <filter
        id="filter0_d_3025_14455"
        x="0"
        y="0.00939941"
        width="50.3086"
        height="50.3079"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3025_14455"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3025_14455"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
