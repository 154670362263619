<template>
  <div class="flex items-center gap-x-[5px] justify-start">
    <span class="relative" :class="isCollapsed ? '' : 'text-[16px]'">
      Создать канал
    </span>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n();
defineProps({
  isCollapsed: {
    type: Boolean,
    default: false,
  },
});
</script>
