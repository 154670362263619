<template>
  <div
    class="fixed inset-0 bg-gray-900 bg-opacity-50 z-[999993] flex justify-center items-center"
  >
    <div
      class="flex flex-col bg-white mobile:overflow-y-auto custom-scrollbar rounded-lg mobile:rounded-none w-4/5 max-w-[620px] mobile:w-full mobile:h-full absolute shadow-in text-[14px]"
    >
      <ModalHeader @click="handleClose" class="mobile:rounded-none" />
      <div
        class="flex flex-col items-start py-[10px] px-[30px] mobile:px-[10px] mobile:pt-[10px] mobile:pb-[50px]"
      >
        <p class="flex gap-[2px] items-center mb-2 mobile:mb-0">
          <span class="font-[600] text-[#797979]">Эксклюзивный статус</span>
          <DiamondIcon class="w-[30px] h-[30px] text-[#FF6663]" />
          <span class="text-[#FFA563] font-[500]">Совладелец</span>
        </p>
        <ol
          class="text-[#797979] pl-[20px] list-outside text-[14px] mobile:leading-[130%] font-[300] mb-[10px] leading-[171%]"
        >
          <li>
            <span class="text-[#2ECC71] mr-1">+1000</span>
            <FooterIcon class="inline w-4 h-4" /> UMBRELLA на счет (≈1000$)
          </li>
          <li>
            <span class="text-[#FF6663]">0.6%</span> комиссия на продажу в P2P
            навсегда, <span class="text-[#2ECC71]">0.2%</span> в начале дня
          </li>
          <li>
            <div class="flex items-center gap-1">
              Статус
              <MerchantIcon class="inline w-6 h-6 mobile:w-5 mobile:h-5ƒ" />
              <span class="text-[#000] text-[15px] mobile:text-[13px]"
                >Мерчант</span
              >
              пожизненно
            </div>
          </li>
          <li>
            <span class="text-[#2ECC71]">0.1%</span> за пополнение на биржу
            пожизненно
          </li>
          <li>
            Выпущено 1000 статусов эксклюзивно, больше не будет в продаже
            никогда!
          </li>
          <li>Никаких банов, никаких T+1</li>
          <li>Нет ограничений на ввод и вывод средств</li>
          <li>Прямой контакт с основателями проекта</li>
          <li>
            <span class="text-[#2ECC71]">50%</span> прибыли от всех комиссий
            ваших рефералов
          </li>
          <li>
            Можно перепродать аккаунт, по нашим оценкам цена будет ~
            <span class="text-[#2ECC71]">20 000$</span>
          </li>
          <li>
            <span class="text-[#FFA563]">Premium Support</span> с приоритетным
            решением вопросов
          </li>
          <li>
            Возможность стать партнером по приему/выдаче наличных с нашей биржи
          </li>
          <li>
            Минимальный % на спот и фьючерсы более 1000 токенов пожизненно
          </li>
          <li>
            Самый высокий % за стейкинг USDT до
            <span class="text-[#2ECC71]">+3% APR</span> к базовому
          </li>
          <li>Минимальный % за криптозаймы (реализуется)</li>
          <li>
            Ранний доступ к листингам, приватной информации и инвестициям в наши
            проекты и микросервисы с приоритетом на вступление
          </li>
          <li>
            Возможность подключить действующий кошелек со стороны, вне биржи с
            полным контролем (реализуется)
          </li>
          <li>
            <div class="flex items-center gap-1">
              Секретный бонус
              <ProfileMenuPresent class="inline w-5 h-5 text-[#FF6663]" />
            </div>
          </li>
        </ol>
        <div
          class="flex flex-col w-full text-[#797979] text-[12px] font-[500] leading-[167%] mobile:leading-[120%] gap-[5px] mb-1"
        >
          <div class="flex justify-between items-center">
            <p>Продажа закончится безвозвратно</p>
            <p class="text-[#FF6663]">59 дней 23 часа 69 минут</p>
          </div>
          <div class="flex justify-between items-center">
            <p>Наличие</p>
            <p>
              <span class="text-[#2ECC71] font-[500]">Осталось 50</span> |
              <span class="text-[#FF6663] font-[500]">Купили 950</span>
            </p>
          </div>
          <div class="flex justify-between items-center">
            <p>Цена</p>
            <p class="text-[14px] mobile:text-[12px] font-[600]">
              <span class="text-[#2ECC71]">999 USDT</span>&nbsp;
              <span class="text-[#FF6663] line-through">10000 USDT</span>
            </p>
          </div>
          <div class="flex justify-between items-center">
            <p>Окупаемость</p>
            <p class="text-[14px] mobile:text-[12px]">~1-14 дней</p>
          </div>
          <div class="flex justify-between items-center">
            <p>Время действия</p>
            <p class="text-[20px] mobile:text-[12px]">∞</p>
          </div>
        </div>
        <div v-if="error" class="text-red-500 text-sm mb-2 text-center w-full">
          {{ error }}
        </div>
        <div class="flex items-center justify-center w-full">
          <button
            @click="handlePurchase"
            :disabled="loading"
            class="px-[40px] text-[14px] bg-[#4ADE80] hover:bg-[#4ADE80]/75 disabled:bg-gray-400 disabled:cursor-not-allowed text-white py-2 rounded-[5px] mobile:fixed mobile:bottom-0 mobile:w-full mobile:h-[40px]"
          >
            {{ loading ? "Загрузка..." : "Купить" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useSubscriptionStore } from "@/stores/subscriptionStore";
import ModalHeader from "./ModalDeal/ModalBlocks/ModalHeader.vue";
import MerchantIcon from "../components/icons/MerchantIcon.vue";
import DiamondIcon from "../components/icons/DiamondIcon.vue";
import ProfileMenuPresent from "../components/icons/ProfileMenuPresent.vue";
import FooterIcon from "./icons/FooterIcon.vue";
import { storeToRefs } from "pinia";

const emit = defineEmits(["close"]);
const subscriptionStore = useSubscriptionStore();
const { loading, error } = storeToRefs(subscriptionStore);

const handleClose = () => {
  emit("close");
};

const handlePurchase = () => {
  subscriptionStore
    .purchaseVipPlan()
    .then(() => {
      handleClose();
    })
    .catch((err) => {
      console.error("Purchase failed:", err);
    });
};
</script>
