//AuthForms.vue
<template>
  <LoginForm
    v-if="authFormsStore.currentForm === 'login'"
    :email="authFormsStore.formData.email"
    @close="closeForm"
    @switch-form="setCurrentForm"
    @login-success="handleLoginSuccess"
    @verification-required="handleLoginVerificationRequired"
  />
  <RegisterForm
    v-if="authFormsStore.currentForm === 'register'"
    ref="registerFormRef"
    :email="authFormsStore.formData.email"
    @close="closeForm"
    @switch-form="setCurrentForm"
    @registration-success="handleRegistrationSuccess"
  />
  <RegisterGoogleTelegram
    v-if="authFormsStore.currentForm === 'google-telegram'"
    :registrationType="authFormsStore.registrationType"
    @close="closeForm"
    @switch-form="setCurrentForm"
  />
  <ResetPassword
    v-if="authFormsStore.currentForm === 'reset-password'"
    @close="closeForm"
    @switch-form="setCurrentForm"
  />
  <ConfirmEmail
    v-if="authFormsStore.currentForm === 'email-verification'"
    :email="authFormsStore.formData.email"
    @close="closeForm"
    @verified="handleEmailVerified"
    @back="handleConfirmEmailBack"
  />
  <LoginVerification
    v-if="authFormsStore.currentForm === 'login-verification'"
    :loginData="authFormsStore.formData"
    @close="closeForm"
    @verified="handleLoginVerified"
    @back="setCurrentForm('login')"
  />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useAuthFormsStore } from "@/stores/authFormsStore";
import LoginForm from "./LoginForm.vue";
import RegisterForm from "./RegisterForm.vue";
import RegisterGoogleTelegram from "./RegisterGoogleTelegram.vue";
import ResetPassword from "./ResetPassword.vue";
import ConfirmEmail from "./ConfirmEmail.vue";
import LoginVerification from "./LoginVerification.vue";

const props = defineProps({
  initialForm: String,
  initialEmail: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["close", "login-success"]);
const authFormsStore = useAuthFormsStore();
const registerFormRef = ref(null);

const setCurrentForm = async (formName, data = {}) => {
  console.group("setCurrentForm");
  console.log("Form name:", formName);
  console.log("Data:", data);

  try {
    await authFormsStore.updateFormState({
      currentForm: formName,
      ...data,
    });
    console.log("Form state updated successfully");
  } catch (error) {
    console.error(`Failed to switch to ${formName} form:`, error);
  } finally {
    console.groupEnd();
  }
};

const handleLoginSuccess = (result) => {
  console.group("handleLoginSuccess in AuthForms");
  console.log("Login success result:", result);

  emit("login-success", result);
  closeForm();

  console.groupEnd();
};

const handleRegistrationSuccess = async (email) => {
  console.log("handleRegistrationSuccess called with email:", email);
  
  // Сохраняем email в локальной переменной для уверенности
  const emailToVerify = email;
  
  try {
    // Сначала устанавливаем email в formData
    authFormsStore.setFormData({
      email: emailToVerify
    });
    
    // Затем обновляем состояние формы
    await authFormsStore.updateFormState({
      currentForm: "email-verification",
      isVisible: true,
      // Дублируем email здесь для надёжности
      formData: {
        email: emailToVerify
      }
    });
    
    // Проверяем, что email установлен после обновления формы
    console.log("After updateFormState, email =", authFormsStore.formData.email);
  } catch (error) {
    console.error("Failed to handle registration success:", error);
  }
};

const handleEmailVerified = async () => {
  console.log("handleEmailVerified called");
  await setCurrentForm("login");
};

const handleConfirmEmailBack = async () => {
  console.log("handleConfirmEmailBack called");
  await setCurrentForm("register", { email: authFormsStore.formData.email });
};

const handleLoginVerificationRequired = async (data) => {
  console.log("handleLoginVerificationRequired called with data:", data);
  await authFormsStore.updateFormState({
    currentForm: "login-verification",
    formData: {
      ...authFormsStore.formData,
      email: data.email,
      password: data.password,
    },
    isVisible: true,
  });
};

const handleLoginVerified = (result) => {
  console.log("handleLoginVerified called with result:", result);
  emit("login-success", result);
  closeForm();
};

const closeForm = async () => {
  console.log("closeForm called");
  await authFormsStore.close();
  emit("close");
};

onMounted(() => {
  console.group("AuthForms onMounted");
  console.log("Initial form:", props.initialForm);
  console.log("Initial email:", props.initialEmail);

  if (props.initialForm || props.initialEmail) {
    console.log("Updating form state with:", {
      currentForm: props.initialForm,
      isVisible: true,
      formData: {
        email: props.initialEmail,
      },
    });

    authFormsStore.updateFormState({
      currentForm: props.initialForm,
      isVisible: true,
      formData: {
        email: props.initialEmail,
      },
    });
  }

  console.groupEnd();
});
</script>
