import { defineStore } from "pinia";
import { ref } from "vue";
import { nextTick } from "vue";

export const useAuthFormsStore = defineStore("authForms", () => {
  const isVisible = ref(false);
  const currentForm = ref(null);
  const initialEmail = ref("");
  const registrationType = ref(null);
  const verificationData = ref(null);
  const formData = ref({
    email: "",
    password: "",
    code: "",
    registrationStep: 1,
  });

  const resetState = () => {
    isVisible.value = false;
    currentForm.value = null;
    initialEmail.value = "";
    registrationType.value = null;
    verificationData.value = null;

    if (!currentForm.value) {
      formData.value = {
        email: "",
        password: "",
        code: "",
        registrationStep: 1,
      };
    }
  };

  const updateFormState = async (newState) => {
    console.log("updateFormState called with:", newState);
    
    if (newState.currentForm !== currentForm.value) {
      isVisible.value = false;
      await nextTick();
    }
  
    // Устанавливаем текущую форму и видимость
    currentForm.value = newState.currentForm;
    isVisible.value = newState.isVisible ?? true;
  
    // Сохраняем текущий email чтобы не потерять его
    const currentEmail = formData.value.email;
    
    // Обновляем formData, сохраняя email если он есть
    if (newState.formData) {
      const newEmail = newState.formData.email || currentEmail;
      
      formData.value = {
        ...formData.value,
        ...newState.formData,
        // Явно устанавливаем email, чтобы убедиться что он не будет перезаписан
        email: newEmail || currentEmail
      };
      
      console.log("Updated formData:", JSON.stringify(formData.value));
    }
  
    // Если initialEmail установлен отдельно, используем его
    if (newState.initialEmail) {
      initialEmail.value = newState.initialEmail;
      formData.value.email = newState.initialEmail;
      console.log("Set email from initialEmail:", formData.value.email);
    }
    
    if (newState.registrationType) {
      registrationType.value = newState.registrationType;
    }
  };

  const switchForm = async (formType, data = {}) => {
    try {
      await close();
      await nextTick();

      switch (formType) {
        case "login":
          await updateFormState({
            isVisible: true,
            currentForm: "login",
            formData: { email: data.email },
            initialEmail: data.email,
          });
          break;
        case "register":
          await updateFormState({
            isVisible: true,
            currentForm: "register",
            formData: { email: data.email, registrationStep: 1 },
            initialEmail: data.email,
          });
          break;
        case "reset-password":
          await updateFormState({
            isVisible: true,
            currentForm: "reset-password",
            formData: { registrationStep: 1 },
          });
          break;
        case "login-verification":
          await updateFormState({
            isVisible: true,
            currentForm: "login-verification",
            formData: {
              email: data.email,
              password: data.password,
            },
          });
          break;
        case "email-verification":
          await updateFormState({
            isVisible: true,
            currentForm: "email-verification",
            verificationData: data,
            formData: { email: data.email },
          });
          break;
        case "google-telegram":
          await updateFormState({
            isVisible: true,
            currentForm: "google-telegram",
            registrationType: data.type,
          });
          break;
        default:
          throw new Error(`Unknown form type: ${formType}`);
      }
    } catch (error) {
      console.error("Failed to switch form:", error);
      throw error;
    }
  };

  const close = async () => {
    isVisible.value = false;
    await nextTick();
    resetState();
  };

  const setFormData = (data) => {
    formData.value = { ...formData.value, ...data };
  };

  const resetFormData = () => {
    formData.value = {
      email: "",
      password: "",
      code: "",
      registrationStep: 1,
    };
  };

  return {
    isVisible,
    currentForm,
    initialEmail,
    registrationType,
    verificationData,
    formData,

    switchForm,
    close,
    setFormData,
    resetFormData,

    updateFormState,
  };
});
