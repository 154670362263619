const messages = {
  ru: {
    chat: {
      header: {
        chatWith: "Чат с",
        roles: {
          buyer: "покупателем",
          seller: "продавцом",
        },
      },
      messages: {
        loading: "Загрузка сообщений...",
        error: {
          title: "Ошибка!",
          retry: "Повторить загрузку",
          loadFailed: "Не удалось загрузить сообщения. Попробуйте еще раз.",
          loadFailedRefresh:
            "Не удалось загрузить сообщения. Попробуйте обновить страницу.",
          loadFailedNewOrder:
            "Не удалось загрузить сообщения для нового заказа.",
          fileOpenError: "Не удалось открыть файл. URL отсутствует.",
          fileDeletionError: "Не удалось удалить файл. Попробуйте еще раз.",
          sendError: "Не удалось отправить сообщение: ",
        },
      },
      input: {
        placeholder: "Введите сообщение",
        files: {
          defaultName: "Прикрепленный файл",
          delete: "Удалить",
        },
      },
      roles: {
        unknown: "Неизвестно",
        buyer: "Покупатель",
        seller: "Продавец",
        support: "Поддержка",
      },
      supportUser: {
        name: "UmbrellaX (Support)",
        online: true,
      },
      merchant: {
        unknownUser: "Неизвестный пользователь",
        userId: "User ID: {id}",
        support: {
          name: "UmbrellaX (Поддержка)",
          letter: "S",
        },
      },
    },
  },
};

export default messages;
